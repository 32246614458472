import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase Firestore configuration
import { collection, getDocs } from "firebase/firestore";
import PackageCard from "../components/PackageCard"; // Assume this is similar to ProductCard but for packages
import "./PackageCard.css"; // Style for the page layout
import PackageModal from "../components/PackageModal"; // Assume this is similar to ProductModal but for packages
import { useUser } from "../context/UserContext";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ProductStep = ({ onSelect, selectedPackage }) => {
  const { user } = useUser();
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalPackage, setModalPackage] = useState(null);
  const [isSolarModalOpen, setSolarModalOpen] = useState(false);
  const [solarPanelCount, setSolarPanelCount] = useState(1);
  // Filters
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "packages"));
        const packageList = querySnapshot.docs
          .map((doc) => {
            let data = doc.data();
            if (user.groupId && user.userGroup?.percentage !== 100) {
              data.prijs = data.prijs * (user.userGroup.percentage / 100);
            }

            return {
              id: doc.id,
              ...data,
            };
          })
          .filter((pkg) => pkg.active)
          .sort((a, b) => a.prijs - b.prijs); // Filter for active packages;
        setPackages(packageList);
        setFilteredPackages(packageList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching packages:", err);
        setError("Failed to load packages. Please try again.");
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  // Filter logic
  useEffect(() => {
    let filtered = packages;

    if (searchQuery) {
      const searchTerms = searchQuery.toLowerCase().split(" ");

      filtered = filtered.filter((pkg) => {
        const packageName = pkg.naam.toLowerCase();
        return searchTerms.every((term) => packageName.includes(term));
      });

      if (searchTerms.includes("solar")) {
        filtered = filtered.filter((pkg) => pkg.solar);
      }
    }

    if (categoryFilter) {
      filtered = filtered.filter((pkg) => pkg.category === categoryFilter);
    }

    if (minPrice) {
      filtered = filtered.filter(
        (pkg) => parseFloat(pkg.prijs) >= parseFloat(minPrice)
      );
    }

    if (maxPrice) {
      filtered = filtered.filter(
        (pkg) => parseFloat(pkg.prijs) <= parseFloat(maxPrice)
      );
    }

    console.log(filtered);
    setFilteredPackages(filtered);
  }, [searchQuery, categoryFilter, minPrice, maxPrice, packages]);

  const handleOpenModal = (packageItem) => {
    setModalPackage(packageItem);
  };

  const handleCloseModal = () => {
    setModalPackage(null);
  };

  const onSelectModal = (packageItem) => {
    onSelect(packageItem);
    setModalPackage(null);
  };
  const onSelectCustom = (packageItem) => {
    if (packageItem.solar) {
      onSelectSolar(packageItem);
    } else {
      onSelect(packageItem);
    }
  };
  const onSelectSolar = (packageItem) => {
    setSelectedProduct(packageItem);
    setSolarModalOpen(true);
  };

  const updateSolarPanels = (newCount) => {
    // setSolarModalOpen(false);
    selectedProduct.solarPanelCount = newCount;
    selectedProduct.solarPrice = newCount * selectedProduct.prijs;
    onSelect(selectedProduct);
  };

  if (loading) return <div>Loading packages...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="package-page">
      <h2>Uw selectie</h2>
      {/* Filters Section */}
      <div className="filters">
        <input
          type="text"
          placeholder="Zoek op naam of kWh..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {/* <select
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
        >
          <option value="">Alle categorieën</option>
          <option value="thuisbatterij">Thuisbatterij</option>
          <option value="zonnepanelen">Zonnepanelen</option>
          <option value="omvormers">Omvormers</option>
        </select> */}
        <input
          type="number"
          placeholder="Min. prijs (€)"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max. prijs (€)"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
      </div>
      <div className="tags-list">
        <div className="tag trans">Vaak gezocht....</div>
        <div className="tag" onClick={() => setSearchQuery("EvaPower")}>
          EvaPower
        </div>
        <div className="tag" onClick={() => setSearchQuery("dyness")}>
          Dyness
        </div>
        <div className="tag" onClick={() => setSearchQuery("pylontech")}>
          Pylontech
        </div>
        <div className="tag" onClick={() => setSearchQuery("goodwe")}>
          GoodWe <small>omvormer</small>
        </div>
        <div className="tag" onClick={() => setSearchQuery("solis")}>
          Solis <small>omvormer</small>
        </div>
        <div className="tag" onClick={() => setSearchQuery("solar")}>
          Zonnepanelen
        </div>
        {searchQuery.length > 0 && (
          <div className="tag danger" onClick={() => setSearchQuery("")}>
            x clear
          </div>
        )}
      </div>
      <div className="package-grid">
        {filteredPackages.map((packageItem) => (
          <PackageCard
            key={packageItem.id}
            packageItem={packageItem}
            isSelected={
              selectedPackage && selectedPackage.id === packageItem.id
            }
            onSelect={() => onSelectCustom(packageItem)}
            onOpenModal={() => handleOpenModal(packageItem)}
          />
        ))}
      </div>
      {modalPackage && (
        <PackageModal
          packageItem={modalPackage}
          onClose={handleCloseModal}
          onSelect={() => onSelectModal(modalPackage)}
        />
      )}
      {isSolarModalOpen && (
        <Modal
          isOpen={isSolarModalOpen}
          toggle={() => setSolarModalOpen(false)}
        >
          <ModalHeader>Selecteer het aantal zonnepanelen</ModalHeader>
          <ModalBody>
            <Label>Aantal zonnepanelen</Label>
            <Input
              type="number"
              min="1"
              value={solarPanelCount}
              onChange={(e) => setSolarPanelCount(Number(e.target.value))}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setSolarModalOpen(false)}>
              Annuleren
            </Button>
            <Button
              color="primary"
              onClick={() => updateSolarPanels(solarPanelCount)}
            >
              Bevestigen
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default ProductStep;
