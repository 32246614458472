import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Progress,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import { useUser } from "../../context/UserContext";

moment.locale("nl");
const SchouwModal = ({ isOpen, onClose, onSave, lead }) => {
  const { user } = useUser();
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [showImageInput, setShowImageInput] = useState(false);
  const [newImageName, setNewImageName] = useState("");

  const [formData, setFormData] = useState({
    schouwDate: moment().format("YYYY-MM-DD"),
    schouwTime: moment().format("HH:mm"),
    schouwer: null,
    typeOfHouse: "",
    buildingYear: "",
    insulation: [],
    roofType: "",
    roofMaterial: "",
    electricityUsage: "",
    solarPanels: "",
    solarPanelDetails: "",
    inverterDetails: "",
    smartMeter: "",
    emergencyPower: "",
    batteryLocation: "",
    inverterLocation: "",
    spaceDetails: "",
    ventilation: "",
    accessNotes: "",
    obstacles: "",
    plannedExtensions: "",
    additionalProducts: "",
    distanceToOmvormer: "",
    distanceToBattery: "",
    availableSpaceOmvormer: "",
    lat: "",
    lng: "",
    photos: [
      { name: "Meterkast", url: "" },
      { name: "Locatie van de batterij", url: "" },
      { name: "Omvormer locatie", url: "" },
      { name: "Toegangspad", url: "" },
      { name: "Ventilatie/openingen", url: "" },
    ],
    opwekking: "",
    dynamicContract: "",
    wattPiek: "",
    inverterNow: "",
    availableSpace: "",
    omvormerSameArea: "",
    kabelTrajectBattery: "",
    kabelTrajectInverter: "",
  });
  const [schouwerSignature, setSchouwerSignature] = useState(null);
  const [clientSignature, setClientSignature] = useState(null);
  const schouwerSigCanvas = React.useRef();
  const clientSigCanvas = React.useRef();

  const [uploading, setUploading] = useState(false);

  const handleClearSignature = (type) => {
    if (type === "client") {
      clientSigCanvas.current.clear();
      setClientSignature("");
    } else {
      schouwerSigCanvas.current.clear();
      setSchouwerSignature("");
    }
    // Reset the saved signature as well
  };

  useEffect(() => {
    const processLead = async () => {
      // console.log("get address");
      try {
        const url = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${
          process.env.REACT_APP_PRO6BB
        }&nl_sixpp=${lead.customerDetails.postcode}&streetnumber=${
          lead.customerDetails.huisnummer
        }&extension=${
          lead.customerDetails.toevoeging ? lead.customerDetails.toevoeging : ""
        }`;
        const response = await fetch(url);

        if (response.ok) {
          const data = await response.json();
          // console.log(data);
          if (data && data.results && data.results.length > 0) {
            const result = data.results[0];
            // console.log(result);
            setFormData((prevData) => ({
              ...prevData,
              schouwDate: moment().format("YYYY-MM-DD"),
              schouwTime: moment().format("HH:mm"),
              lat: result.lat,
              lng: result.lng,
              buildingYear: result.construction_year,
            }));
          }
        }
      } catch (error) {}

      if (user) {
        setFormData((prevData) => ({
          ...prevData,
          schouwer: user,
        }));
      }
    };

    processLead();
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleMultiSelectChange = (e) => {
    const options = e.target.options;
    const values = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    setFormData({
      ...formData,
      [e.target.name]: values,
    });
  };

  const handleFileUpload = (e, photoName) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const storageRef = ref(storage, `schouw-photos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        console.error("Upload failed:", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setFormData((prev) => ({
          ...prev,
          photos: prev.photos.map((photo) =>
            photo.name === photoName ? { ...photo, url: downloadURL } : photo
          ),
        }));
        setUploading(false);
      }
    );
  };

  const handleAdditionalImageUpload = (e) => {
    const file = e.target.files[0];
    const trimmedName = newImageName.trim();
    if (!file || !trimmedName) {
      alert("Geef een geldige naam voor de afbeelding.");
      return;
    }

    setUploading(true);
    const storageRef = ref(storage, `schouw-photos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        console.error("Upload failed:", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setFormData((prev) => ({
          ...prev,
          photos: [...prev.photos, { name: trimmedName, url: downloadURL }],
        }));
        setUploading(false);
        setNewImageName("");
        setShowImageInput(false);
      }
    );
  };

  const removeImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      photos: prev.photos.map((photo, i) =>
        i === index ? { ...photo, url: "" } : photo
      ),
    }));
  };

  const validateStep2 = () => {
    const newErrors = {};

    if (!formData.schouwDate) {
      newErrors.schouwDate = "Schouwdatum is verplicht.";
    }
    if (!formData.schouwTime) {
      newErrors.schouwTime = "Schouwtijd is verplicht.";
    }
    if (!formData.typeOfHouse) {
      newErrors.typeOfHouse = "Type woning is verplicht.";
    }
    // if (formData.insulation.length === 0) {
    //   newErrors.insulation = "Isolatie is verplicht.";
    // }
    if (!formData.buildingYear) {
      newErrors.buildingYear = "Bouwjaar woning is verplicht.";
    }
    if (!formData.roofType) {
      newErrors.roofType = "Type dak is verplicht.";
    }
    if (!formData.roofMaterial) {
      newErrors.roofMaterial = "Dakbedekking is verplicht.";
    }

    setErrors(newErrors);
    return newErrors;
  };

  const validateStep3 = () => {
    const newErrors = {};

    if (!formData.electricityUsage) {
      newErrors.electricityUsage = "Verbruik is verplicht.";
    }

    if (!formData.opwekking) {
      newErrors.opwekking = "Opwekking is verplicht. Bij geen vul 0 in.";
    }
    if (!formData.smartMeter) {
      newErrors.smartMeter = "Slimme meter is verplicht.";
    }

    if (!formData.dynamicContract) {
      newErrors.dynamicContract = "Dynamisch contract is verplicht. ";
    }

    if (!formData.solarPanels) {
      newErrors.solarPanels =
        "Aantal zonnepanelen is verplicht. Bij geen vul 0 in.";
    }
    if (formData.solarPanels > 0) {
      if (!formData.wattPiek) {
        newErrors.wattPiek = "Aantal Wp zonnepanelen is verplicht. ";
      }
      if (!formData.inverterNow) {
        newErrors.inverterNow = "Huidige omvormer en vermogen is verplicht. ";
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  const validateStep4 = () => {
    const newErrors = {};

    if (!formData.batteryLocation) {
      newErrors.batteryLocation = "Locatie van de batterij is verplicht.";
    }

    if (!formData.availableSpace) {
      newErrors.availableSpace =
        "Beschikbare ruimte voor de batterij is verplicht.";
    }

    if (!formData.distanceToBattery) {
      newErrors.distanceToBattery =
        "Afstand meterkast naar batterij is verplicht.";
    }

    if (!formData.kabelTrajectBattery) {
      newErrors.kabelTrajectBattery = "Kabeltraject batterij is verplicht.";
    }

    // if (!formData.omvormerSameArea) {
    //   newErrors.omvormerSameArea =
    //     "Komt de omvormer in dezelfde ruimte is verplicht.";
    // }
    if (formData.omvormerSameArea) {
      if (formData.omvormerSameArea === "Ja") {
        setFormData((prev) => ({
          ...prev,
          inverterLocation: formData.batteryLocation,
          availableSpaceOmvormer: formData.availableSpace,
          distanceToOmvormer: formData.distanceToBattery,
          kabelTrajectBattery: formData.kabelTrajectInverter,
        }));
      } else if (formData.omvormerSameArea === "Nee") {
        if (!formData.inverterLocation) {
          newErrors.inverterLocation = "Locatie van de omvormer is verplicht.";
        }

        if (!formData.availableSpaceOmvormer) {
          newErrors.availableSpaceOmvormer =
            "Beschikbare ruimte voor de omvormer is verplicht.";
        }

        if (!formData.distanceToOmvormer) {
          newErrors.distanceToOmvormer =
            "Afstand meterkast naar omvormer is verplicht.";
        }
        if (!formData.kabelTrajectInverter) {
          newErrors.kabelTrajectInverter =
            "Kabeltraject omvormer is verplicht.";
        }
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  const validateStep5 = () => {
    const errors = {};
    const mandatoryPhotos = [
      "Meterkast",
      "Locatie van de batterij",
      "Omvormer locatie",
    ];
    const missingPhotos = mandatoryPhotos.filter(
      (photoName) =>
        !formData.photos.some((photo) => photo.name === photoName && photo.url)
    );

    if (missingPhotos.length > 0) {
      errors.photos = `De volgende afbeeldingen ontbreken: ${missingPhotos.join(
        ", "
      )}.`;
    }

    setErrors(errors);
    return errors;
  };

  const handleNext = () => {
    if (step === 2) {
      const validationErrors = validateStep2();
      if (Object.keys(validationErrors).length > 0) {
        return; // Stop the user from proceeding
      }
    }

    if (step === 3) {
      const validationErrors = validateStep3();
      if (Object.keys(validationErrors).length > 0) {
        return; // Stop the user from proceeding
      }
    }

    if (step === 4) {
      const validationErrors = validateStep4();
      if (Object.keys(validationErrors).length > 0) {
        return; // Stop the user from proceeding
      }
    }
    if (step === 5) {
      const validationErrors = validateStep5();
      if (Object.keys(validationErrors).length > 0) {
        return; // Stop progression if validation fails
      }
    }
    setErrors([]);
    if (step < 6) setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = () => {
    let status;
    let fase = lead.fase;
    // let fase = "offerte_verzonden";
    if (lead.fase === "wachten_op_akkoord") {
      status = "schouw_controleren";
      fase = "schouw_controleren";
    } else {
      status = "schouw_controleren";
    }
    const updatedLead = {
      ...lead,
      schouwDetails: {
        ...formData,
        schouwerSignature,
        clientSignature,
      },
      fase: fase,
      status: status,
    };

    // console.log(updatedLead);
    onSave(updatedLead);
    onClose();
  };
  // console.log(formData);
  return (
    <Modal className="modal-schouw" isOpen={isOpen} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>Schouw Uitvoeren</ModalHeader>
      <ModalBody>
        <Container>
          <Progress value={(step / 5) * 100} className="mb-4" />

          {step === 1 && (
            <Row>
              <Col>
                <h4>Klantinformatie</h4>
                <p>
                  <strong>Naam:</strong> {lead?.customerDetails?.firstName}{" "}
                  {lead?.customerDetails?.lastName}
                  <br />
                  <strong>Adres:</strong> {lead?.customerDetails?.straat}{" "}
                  {lead?.customerDetails?.huisnummer},{" "}
                  {lead?.customerDetails?.plaats}
                </p>
                <p>
                  <strong>Telefoonnummer:</strong>{" "}
                  {lead?.customerDetails?.phone}
                  <br />
                  <strong>E-mailadres:</strong> {lead?.customerDetails?.email}
                </p>
                <h5>Productinformatie</h5>

                <p style={{ fontStyle: "italic", fontWeight: 500 }}>
                  Gekozen thuisbatterij: <br />
                  {lead?.selectedProduct?.naam}
                  <br />
                  <a
                    target="_blank"
                    href={lead?.selectedProduct?.datasheets[0]}
                  >
                    Download datasheet
                  </a>
                </p>
                <p>Extra producten:</p>

                <Row>
                  {lead?.selectedExtras.length > 0 &&
                    lead.selectedExtras.map((product, index) => {
                      // console.log(product);
                      return (
                        <Col xs={4} key={index}>
                          <div className="product-box">
                            <h3>{product.name}</h3>
                            <br />
                            {product.pdfs.length > 0 && (
                              <a target="_blank" href={product.pdfs[0]}>
                                Download
                              </a>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: product.description,
                              }}
                            ></p>
                            {product.images.length > 0 && (
                              <img src={product.images[0]} />
                            )}
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          )}
          {step === 2 && (
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="schouwDate">Schouwdatum *</Label>
                    <Input
                      type="date"
                      name="schouwDate"
                      id="schouwDate"
                      value={formData.schouwDate}
                      onChange={handleChange}
                      className={errors.schouwDate ? "is-invalid" : ""}
                      disabled
                    />
                    {errors.schouwDate && (
                      <small className="text-danger">{errors.schouwDate}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="schouwTime">Schouwtijd *</Label>
                    <Input
                      type="time"
                      name="schouwTime"
                      id="schouwTime"
                      value={formData.schouwTime}
                      onChange={handleChange}
                      className={errors.schouwTime ? "is-invalid" : ""}
                      disabled
                    />
                    {errors.schouwTime && (
                      <small className="text-danger">{errors.schouwTime}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="typeOfHouse">Type woning *</Label>
                    <Input
                      type="select"
                      name="typeOfHouse"
                      id="typeOfHouse"
                      value={formData.typeOfHouse}
                      onChange={handleChange}
                      className={errors.typeOfHouse ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer</option>
                      <option value="Vrijstaand">Vrijstaand</option>
                      <option value="Hoekwoning">Hoekwoning</option>
                      <option value="Tussenwoning">Tussenwoning</option>
                      <option value="Appartement">Appartement</option>
                      <option value="Anders">Anders</option>
                    </Input>
                    {errors.typeOfHouse && (
                      <small className="text-danger">
                        {errors.typeOfHouse}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="buildingYear">Bouwjaar woning *</Label>
                    <Input
                      type="text"
                      name="buildingYear"
                      id="buildingYear"
                      value={formData.buildingYear}
                      onChange={handleChange}
                      className={errors.buildingYear ? "is-invalid" : ""}
                    />
                    {errors.buildingYear && (
                      <small className="text-danger">
                        {errors.buildingYear}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="roofType">Type dak *</Label>
                    <Input
                      type="select"
                      name="roofType"
                      id="roofType"
                      value={formData.roofType}
                      onChange={handleChange}
                      className={errors.roofType ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer</option>
                      <option value="Plat">Plat</option>
                      <option value="Schuin">Schuin</option>
                      <option value="Anders">Anders</option>
                    </Input>
                    {errors.roofType && (
                      <small className="text-danger">{errors.roofType}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="roofMaterial">Dakbedekking *</Label>
                    <Input
                      type="select"
                      name="roofMaterial"
                      id="roofMaterial"
                      value={formData.roofMaterial}
                      onChange={handleChange}
                      className={errors.roofMaterial ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer een optie</option>
                      <option value="Dakpannen">Dakpannen</option>
                      <option value="Ongeïsoleerdbitumen">Bitumen</option>
                      <option value="Geïsoleerd bitumen">Bitumen</option>
                      <option value="Rieten dak">Rieten dak</option>
                      <option value="EPDM">EPDM</option>
                      <option value="PVC">PVC</option>
                      <option value="Groendak">Groendak</option>
                      <option value="Zink">Zink</option>
                      <option value="Metaal">Metaal</option>
                      <option value="Anders">Anders</option>
                    </Input>
                    {errors.roofMaterial && (
                      <small className="text-danger">
                        {errors.roofMaterial}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="insulation">Isolatievoorzieningen</Label>
                    <Input
                      type="select"
                      name="insulation"
                      id="insulation"
                      value=""
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value && !formData.insulation.includes(value)) {
                          setFormData((prev) => ({
                            ...prev,
                            insulation: [...prev.insulation, value],
                          }));
                        }
                      }}
                      className={errors.insulation ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer een optie</option>
                      <option value="Dakisolatie">Dakisolatie</option>
                      <option value="Vloerisolatie">Vloerisolatie</option>
                      <option value="Spouwmuurisolatie">
                        Spouwmuurisolatie
                      </option>
                      <option value="Dubbelglas">Dubbelglas</option>
                      <option value="Geen">Geen</option>
                      <option value="Anders">Anders</option>
                    </Input>

                    {/* Render selected pills */}
                    <div className="mt-2 dropdown-pills">
                      {formData.insulation.map((item, index) => (
                        <span
                          key={index}
                          className="badge bg-primary me-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFormData((prev) => ({
                              ...prev,
                              insulation: prev.insulation.filter(
                                (ins) => ins !== item
                              ),
                            }));
                          }}
                        >
                          {item} <span>&times;</span>
                        </span>
                      ))}
                    </div>
                    {errors.insulation && (
                      <small className="text-danger">{errors.insulation}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="stepNotes2">
                      Extra opmerkingen voor deze stap
                    </Label>
                    <Input
                      type="textarea"
                      name="stepNotes2"
                      id="stepNotes2"
                      value={formData.stepNotes2}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )}

          {step === 3 && (
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="electricityUsage">
                      Gemiddeld verbruik (kWh/jaar)
                    </Label>
                    <Input
                      type="text"
                      name="electricityUsage"
                      id="electricityUsage"
                      value={formData.electricityUsage}
                      onChange={handleChange}
                      className={errors.electricityUsage ? "is-invalid" : ""}
                    />
                    {errors.electricityUsage && (
                      <small className="text-danger">
                        {errors.electricityUsage}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="opwekking">
                      Gemiddeld opwekking (kWh/jaar)
                    </Label>
                    <Input
                      type="text"
                      name="opwekking"
                      id="opwekking"
                      value={formData.opwekking}
                      onChange={handleChange}
                      className={errors.opwekking ? "is-invalid" : ""}
                    />
                    {errors.opwekking && (
                      <small className="text-danger">{errors.opwekking}</small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="smartMeter">Heeft u een slimme meter? </Label>
                    <Input
                      type="select"
                      name="smartMeter"
                      id="smartMeter"
                      value={formData.smartMeter}
                      onChange={handleChange}
                      className={errors.smartMeter ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer</option>
                      <option value="Ja, versie 5">Ja, versie 5</option>
                      <option value="Ja, versie 4">Ja, versie 4</option>
                      <option value="Ja, ouder">Ja, ouder</option>
                      <option value="Nee">Nee</option>
                    </Input>

                    {errors.smartMeter && (
                      <small className="text-danger">{errors.smartMeter}</small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="solarPanels">Aantal zonnepanelen</Label>
                    <Input
                      type="number"
                      name="solarPanels"
                      id="solarPanels"
                      value={formData.solarPanels}
                      onChange={handleChange}
                      className={errors.solarPanels ? "is-invalid" : ""}
                    />
                    {errors.solarPanels && (
                      <small className="text-danger">
                        {errors.solarPanels}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="dynamicContract">
                      Heeft u een dynamisch energiecontract?
                    </Label>
                    <Input
                      type="select"
                      name="dynamicContract"
                      id="dynamicContract"
                      value={formData.dynamicContract}
                      onChange={handleChange}
                      className={errors.dynamicContract ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer</option>
                      <option value="Ja">Ja</option>
                      <option value="Nee">Nee</option>
                    </Input>
                    {errors.dynamicContract && (
                      <small className="text-danger">
                        {errors.dynamicContract}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {formData.solarPanels > 0 && (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="wattPiek">Aantal Wp</Label>
                      <Input
                        type="text"
                        name="wattPiek"
                        id="wattPiek"
                        value={formData.wattPiek}
                        onChange={handleChange}
                        className={errors.wattPiek ? "is-invalid" : ""}
                      />
                      {errors.wattPiek && (
                        <small className="text-danger">{errors.wattPiek}</small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="inverterNow">
                        Soort omvormer en vermogen
                      </Label>
                      <Input
                        type="text"
                        name="inverterNow"
                        id="inverterNow"
                        value={formData.inverterNow}
                        onChange={handleChange}
                        placeholder="Bijv. GoodWe 10kwh hybride omvormer"
                        className={errors.inverterNow ? "is-invalid" : ""}
                      />
                      {errors.inverterNow && (
                        <small className="text-danger">
                          {errors.inverterNow}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="stepNotes3">
                      Extra opmerkingen voor deze stap
                    </Label>
                    <Input
                      type="textarea"
                      name="stepNotes3"
                      id="stepNotes3"
                      value={formData.stepNotes3}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )}

          {step === 4 && (
            <Form>
              <h3>Batterij</h3>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="batteryLocation">Locatie van de batterij</Label>
                    <Input
                      type="text"
                      name="batteryLocation"
                      id="batteryLocation"
                      value={formData.batteryLocation}
                      onChange={handleChange}
                      className={errors.batteryLocation ? "is-invalid" : ""}
                    />
                    {errors.batteryLocation && (
                      <small className="text-danger">
                        {errors.batteryLocation}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="availableSpace">
                      Beschikbare ruimte voor batterij (m²)
                    </Label>
                    <Input
                      type="number"
                      name="availableSpace"
                      id="availableSpace"
                      value={formData.availableSpace}
                      onChange={handleChange}
                      className={errors.availableSpace ? "is-invalid" : ""}
                    />
                    {errors.availableSpace && (
                      <small className="text-danger">
                        {errors.availableSpace}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="distanceToBattery">
                      Afstand meterkast naar batterijlocatie (m)
                    </Label>
                    <Input
                      type="number"
                      name="distanceToBattery"
                      id="distanceToBattery"
                      value={formData.distanceToBattery}
                      onChange={handleChange}
                      className={errors.distanceToBattery ? "is-invalid" : ""}
                    />
                    {errors.distanceToBattery && (
                      <small className="text-danger">
                        {errors.distanceToBattery}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  {/* <FormGroup>
                    <Label for="omvormerSameArea">
                      Komt de omvormer in dezelfde ruimte?
                    </Label>
                    <Input
                      type="select"
                      name="omvormerSameArea"
                      id="omvormerSameArea"
                      value={formData.omvormerSameArea}
                      onChange={handleChange}
                      className={errors.omvormerSameArea ? "is-invalid" : ""}
                    >
                      <option value="">Selecteer</option>
                      <option value="Ja">Ja</option>
                      <option value="Nee">Nee</option>
                    </Input>
                    {errors.omvormerSameArea && (
                      <small className="text-danger">
                        {errors.omvormerSameArea}
                      </small>
                    )}
                  </FormGroup> */}
                </Col>
              </Row>
              {formData.omvormerSameArea === "Nee" && (
                <>
                  <h3>Omvormer</h3>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="inverterLocation">
                          Locatie van de omvormer
                        </Label>
                        <Input
                          type="text"
                          name="inverterLocation"
                          id="inverterLocation"
                          value={formData.inverterLocation}
                          onChange={handleChange}
                          className={
                            errors.inverterLocation ? "is-invalid" : ""
                          }
                        />
                        {errors.inverterLocation && (
                          <small className="text-danger">
                            {errors.inverterLocation}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="availableSpaceOmvormer">
                          Beschikbare ruimte voor de omvormer (m²)
                        </Label>
                        <Input
                          type="number"
                          name="availableSpaceOmvormer"
                          id="availableSpaceOmvormer"
                          value={formData.availableSpaceOmvormer}
                          onChange={handleChange}
                          className={
                            errors.availableSpaceOmvormer ? "is-invalid" : ""
                          }
                        />
                        {errors.availableSpaceOmvormer && (
                          <small className="text-danger">
                            {errors.availableSpaceOmvormer}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="distanceToOmvormer">
                          Afstand meterkast naar Omvormer (m)
                        </Label>
                        <Input
                          type="number"
                          name="distanceToOmvormer"
                          id="distanceToOmvormer"
                          value={formData.distanceToOmvormer}
                          onChange={handleChange}
                          className={
                            errors.distanceToOmvormer ? "is-invalid" : ""
                          }
                        />
                        {errors.distanceToOmvormer && (
                          <small className="text-danger">
                            {errors.distanceToOmvormer}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                    <Col></Col>
                  </Row>
                </>
              )}
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="kabelTrajectBattery">
                      Beschrijf kabeltraject van batterij naar meterkast
                    </Label>
                    <Input
                      type="textarea"
                      name="kabelTrajectBattery"
                      id="kabelTrajectBattery"
                      value={formData.kabelTrajectBattery}
                      onChange={handleChange}
                      placeholder={`Beschrijf duidelijk hoe het kabeltraject van de batterij ${
                        formData.omvormerSameArea === "Ja" ? "en omvormer" : ""
                      } gaat verlopen. Bespreek dit goed met de klant.`}
                      className={errors.kabelTrajectBattery ? "is-invalid" : ""}
                    />
                    {errors.kabelTrajectBattery && (
                      <small className="text-danger">
                        {errors.kabelTrajectBattery}
                      </small>
                    )}
                  </FormGroup>
                </Col>
                {formData.omvormerSameArea === "Nee" && (
                  <Col>
                    <FormGroup>
                      <Label for="kabelTrajectInverter">
                        Beschrijf kabeltraject van omvormer naar meterkast
                      </Label>
                      <Input
                        type="textarea"
                        name="kabelTrajectInverter"
                        id="kabelTrajectInverter"
                        value={formData.kabelTrajectInverter}
                        onChange={handleChange}
                        placeholder="Beschrijf duidelijk hoe het kabeltraject van de omvormer gaat verlopen. Bespreek dit goed met de klant."
                        className={
                          errors.kabelTrajectInverter ? "is-invalid" : ""
                        }
                      />
                      {errors.kabelTrajectInverter && (
                        <small className="text-danger">
                          {errors.kabelTrajectInverter}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="stepNotes4">
                      Extra opmerkingen voor deze stap
                    </Label>
                    <Input
                      type="textarea"
                      name="stepNotes4"
                      id="stepNotes4"
                      value={formData.stepNotes4}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )}

          {step === 5 && (
            <Form>
              <Row>
                {formData.photos.map((photo, index) => (
                  <Col xs={4} key={index}>
                    <FormGroup>
                      <h3 className="image-upload-title">{photo.name}</h3>
                      {!photo.url ? (
                        <Label
                          for={`photo-${index}`}
                          className={`image-actions ${
                            errors.photos && errors.photos.includes(photo.name)
                          }`}
                        >
                          <span>Upload afbeelding</span>
                          <Input
                            type="file"
                            id={`photo-${index}`}
                            accept="image/*"
                            onChange={(e) => handleFileUpload(e, photo.name)}
                            className={`mt-2 ${
                              errors.photos &&
                              errors.photos.includes(photo.name)
                                ? "is-invalid"
                                : ""
                            }`}
                            hidden
                          />
                        </Label>
                      ) : (
                        <div className="image-actions">
                          <a
                            href={photo.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Bekijk afbeelding
                          </a>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => removeImage(index)}
                            className="ms-2"
                          >
                            Verwijderen
                          </Button>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                ))}
                {errors.photos && (
                  <small className="text-danger">{errors.photos}</small>
                )}
                {showImageInput && (
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="newImageName">
                        Naam voor nieuwe afbeelding
                      </Label>
                      <Input
                        type="text"
                        id="newImageName"
                        value={newImageName}
                        onChange={(e) => setNewImageName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="additionalPhoto">
                        Upload nieuwe afbeelding
                      </Label>
                      <Input
                        type="file"
                        id="additionalPhoto"
                        accept="image/*"
                        onChange={handleAdditionalImageUpload}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Button
                color="link"
                onClick={() => setShowImageInput(!showImageInput)}
              >
                {showImageInput ? "Annuleren" : "Voeg nieuwe afbeelding toe"}
              </Button>
              <div className="uploaded-photos mt-3">
                <h5>Geüploade afbeeldingen</h5>
                <ul>
                  {formData.photos
                    .filter((photo) => photo.url)
                    .map((photo, index) => (
                      <li key={index}>
                        <a
                          href={photo.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {photo.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <FormGroup>
                <Label for="accessNotes">Opmerkingen</Label>
                <Input
                  type="textarea"
                  name="accessNotes"
                  id="accessNotes"
                  value={formData.accessNotes}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          )}

          {step === 6 && (
            <div style={{ position: "relative" }}>
              <h4 className="mb-4">Schouwrapport Overzicht</h4>
              <div className="schouwer-info">
                Uitgevoerd door: <br />
                {formData?.schouwer.personalInfo.firstName}{" "}
                {formData?.schouwer.personalInfo.lastName}
                <br />
                {formData?.schouwer.personalInfo.phoneNumber}
                <br />
                {formData?.schouwer.email}
              </div>
              <h5>Klant gegevens</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Naam</td>
                    <td>
                      {lead?.customerDetails.firstName}{" "}
                      {lead?.customerDetails.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td>Adres</td>
                    <td>
                      {lead?.customerDetails.straat}{" "}
                      {lead?.customerDetails.huisnummer}{" "}
                      {lead?.customerDetails.toevoeging}
                      <br />
                      {lead?.customerDetails.postcode}{" "}
                      {lead?.customerDetails.plaats}
                    </td>
                  </tr>
                  <tr>
                    <td>Telefoonnummer</td>
                    <td>{lead?.customerDetails.phone}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{lead?.customerDetails.email}</td>
                  </tr>
                </tbody>
              </Table>

              <h5>Algemene Informatie</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Schouwdatum</td>
                    <td>{formData.schouwDate}</td>
                  </tr>
                  <tr>
                    <td>Schouwtijd</td>
                    <td>{formData.schouwTime}</td>
                  </tr>
                  <tr>
                    <td>Type woning</td>
                    <td>{formData.typeOfHouse}</td>
                  </tr>
                  <tr>
                    <td>Bouwjaar</td>
                    <td>{formData.buildingYear}</td>
                  </tr>
                  <tr>
                    <td>Isolatie</td>
                    <td>{formData.insulation.join(", ")}</td>
                  </tr>
                  <tr>
                    <td>Type dak</td>
                    <td>{formData.roofType}</td>
                  </tr>
                  <tr>
                    <td>Dakbedekking</td>
                    <td>{formData.roofMaterial}</td>
                  </tr>
                </tbody>
              </Table>

              <h5>Technische Gegevens</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Gemiddeld elektriciteitsverbruik</td>
                    <td>{formData.electricityUsage} kWh/jaar</td>
                  </tr>
                  <tr>
                    <td>Aantal zonnepanelen</td>
                    <td>{formData.solarPanels}</td>
                  </tr>
                  <tr>
                    <td>Opwekking</td>
                    <td>{formData.opwekking} kWh/jaar</td>
                  </tr>
                  <tr>
                    <td>Huidige omvormer</td>
                    <td>{formData.inverterNow}</td>
                  </tr>
                </tbody>
              </Table>

              <h5>Installatie Details</h5>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Locatie van de batterij</td>
                    <td>{formData.batteryLocation}</td>
                  </tr>
                  <tr>
                    <td>Kabeltraject batterij</td>
                    <td>{formData.kabelTrajectBattery}</td>
                  </tr>
                </tbody>
              </Table>

              <h5>Foto's</h5>
              <ul>
                {formData.photos.map((photo, index) => (
                  <li key={index}>
                    <strong>{photo.name}:</strong>{" "}
                    {photo.url ? (
                      <a
                        href={photo.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Bekijk afbeelding
                      </a>
                    ) : (
                      "Geen afbeelding geüpload"
                    )}
                  </li>
                ))}
              </ul>

              <h5>Handtekeningen</h5>
              <div className="mb-4 text-left">
                <FormGroup>
                  <Label for="schouwerSignature">
                    Handtekening Schouwer <br />{" "}
                    {formData.schouwer.personalInfo.firstName}{" "}
                    {formData.schouwer.personalInfo.lastName}
                  </Label>
                  <SignatureCanvas
                    ref={schouwerSigCanvas}
                    penColor="black"
                    canvasProps={{
                      width: 320,
                      height: 150,
                      className: "sigCanvas",
                    }}
                    onEnd={() =>
                      setSchouwerSignature(
                        schouwerSigCanvas.current
                          .getTrimmedCanvas()
                          .toDataURL("image/png")
                      )
                    }
                  />
                  <div style={{ marginTop: "10px" }}>
                    <button
                      onClick={handleClearSignature}
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "#ff4d4f",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Opnieuw
                    </button>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="clientSignature">
                    Handtekening Klant
                    <br /> {lead.customerDetails.firstName}{" "}
                    {lead.customerDetails.lastName}
                  </Label>
                  <SignatureCanvas
                    ref={clientSigCanvas}
                    penColor="black"
                    canvasProps={{
                      width: 320,
                      height: 150,
                      className: "sigCanvas",
                    }}
                    onEnd={() =>
                      setClientSignature(
                        clientSigCanvas.current
                          .getTrimmedCanvas()
                          .toDataURL("image/png")
                      )
                    }
                  />
                  <div style={{ marginTop: "10px" }}>
                    <button
                      onClick={() => handleClearSignature("client")}
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "#ff4d4f",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Opnieuw
                    </button>
                  </div>
                </FormGroup>
              </div>
            </div>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        {step > 1 && (
          <Button color="secondary" onClick={handlePrevious}>
            Vorige
          </Button>
        )}
        {step < 6 && (
          <Button color="primary" onClick={handleNext}>
            Volgende
          </Button>
        )}
        {step === 6 && (
          <Button color="primary" onClick={handleSubmit} disabled={uploading}>
            Opslaan
          </Button>
        )}
        <Button color="secondary" onClick={onClose}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SchouwModal;
