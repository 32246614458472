import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import moment from "moment";
import "./AdminLeads.css";
import { convertToDate, toastHelper } from "../../utils/helpers";

const AdminLeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [agents, setAgents] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const defaultLead = {
    agent: null,
    archived: false,
    selectedProduct: null,
    customerDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      straat: "",
      plaats: "",
      provincie: "",
      woonfunctie: "",
      lat: "",
      lng: "",
      oppervlakte: "",
      bouwjaar: "",
      huisnummer: "",
      toevoeging: "",
      postcode: "",
    },
    selectedExtras: [],
    notes: [],
    appliedDiscounts: [],
    finalPrice: 0,
    passcode: null,
    priceDetails: {
      VAT: 0,
      basePrice: 0,
      discountedPrice: 0,
      totalIncludingVAT: 0,
    },
    status: "new",
    signature: null,
    signedAt: null,
    sentAt: null,
    createdAt: null,
    updatedAt: null,
  };
  const [newLead, setNewLead] = useState(defaultLead);

  const [newNote, setNewNote] = useState("");

  const fetchLeads = async () => {
    try {
      const leadsSnap = await getDocs(collection(db, "leads"));
      let fetchedLeads = leadsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: convertToDate(doc.data().createdAt),
      }));
      console.log(fetchedLeads);
      fetchedLeads = fetchedLeads.sort((a, b) => {
        // Assuming createdAt is a Firestore Timestamp object
        const aDate = b.createdAt;
        const bDate = a.createdAt;
        return aDate - bDate; // Ascending order
      });
      setLeads(fetchedLeads);
      setFilteredLeads(fetchedLeads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  const fetchAgents = async () => {
    try {
      const agentsSnap = await getDocs(collection(db, "users"));
      const fetchedAgents = agentsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const roleOrder = ["admin", "manager", "agent"]; // Define the custom role order

      const sortedAgents = fetchedAgents.sort((a, b) => {
        const roleA = roleOrder.indexOf(a.role);
        const roleB = roleOrder.indexOf(b.role);

        // Roles with higher index come later
        return roleA - roleB;
      });

      setAgents(sortedAgents);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchAgents();
  }, []);

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleEditModal = () => {
    setModalOpen(!editModalOpen);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const lowerQuery = query.toLowerCase();
    const filtered = leads.filter(
      (lead) =>
        lead.customerDetails.firstName.toLowerCase().includes(lowerQuery) ||
        lead.customerDetails.lastName.toLowerCase().includes(lowerQuery) ||
        lead.customerDetails.email.toLowerCase().includes(lowerQuery) ||
        lead.customerDetails.straat.toLowerCase().includes(lowerQuery) ||
        lead.customerDetails.plaats.toLowerCase().includes(lowerQuery)
    );
    setFilteredLeads(filtered);
  };

  const handleArchiveLead = async (leadId) => {
    console.log(leadId);
    try {
      // Find the lead to archive
      const leadToArchive = leads.find((lead) => lead.id === leadId);
      if (!leadToArchive) {
        alert("Lead not found.");
        return;
      }

      // Reference to the archivedLeads collection
      const archivedLeadRef = doc(db, "archivedLeads", leadId);

      // Move the lead to archivedLeads collection
      await setDoc(archivedLeadRef, {
        ...leadToArchive,
        archivedAt: new Date(), // Add a timestamp for archiving
      });

      // Delete the lead from the leads collection
      const leadRef = doc(db, "leads", leadId);
      await deleteDoc(leadRef);

      // Update state to remove the lead
      setLeads((prevLeads) => prevLeads.filter((lead) => lead.id !== leadId));

      toastHelper.success("Lead successfully archived.");
    } catch (error) {
      console.error("Error archiving lead:", error);
      toastHelper.error("Failed to archive lead. Please try again.");
    }
  };

  const handleAddressSearch = async () => {
    const { postcode, huisnummer, toevoeging } = newLead.customerDetails;
    if (!postcode || !huisnummer) return;

    try {
      const url = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${
        process.env.REACT_APP_PRO6BB
      }&nl_sixpp=${postcode}&streetnumber=${huisnummer}&extension=${
        toevoeging || ""
      }`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        if (data?.results?.length > 0) {
          const result = data.results[0];
          setNewLead((prev) => ({
            ...prev,
            customerDetails: {
              ...prev.customerDetails,
              straat: result.street,
              plaats: result.city,
              provincie: result.province,
              woonfunctie: result.functions,
              lat: result.lat,
              lng: result.lng,
              oppervlakte: result.surface,
              bouwjaar: result.construction_year,
            },
          }));
        }
      } else {
        alert("Geen adres gevonden.");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      alert("Fout bij het ophalen van adresgegevens.");
    }
  };

  const handleAddNote = () => {
    if (!newNote.trim()) {
      alert("Notitie mag niet leeg zijn.");
      return;
    }

    setNewLead((prev) => ({
      ...prev,
      notes: [
        ...prev.notes,
        { content: newNote, createdAt: new Date(), user: "Admin" },
      ],
    }));
    setNewNote(""); // Clear the text area
  };

  const handleAddLead = async () => {
    // console.log(newLead);
    // return;
    const {
      customerDetails: { firstName, lastName, email, phone, straat, plaats },
    } = newLead;

    if (!firstName || !lastName || !email || !phone || !straat || !plaats) {
      alert("Vul alle verplichte velden in.");
      return;
    }
    const dataObject = {
      ...newLead,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    console.log(dataObject);
    try {
      if (newLead.id) {
        const leadsRefId = doc(db, "leads", newLead.id);
        await updateDoc(leadsRefId, dataObject);
      } else {
        await addDoc(collection(db, "leads"), dataObject);
      }

      toastHelper.success("Lead is toegevoegd!");
      fetchLeads();
      setNewLead({
        agent: null,
        archived: false,
        selectedProduct: null,
        customerDetails: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          straat: "",
          plaats: "",
          provincie: "",
          woonfunctie: "",
          lat: "",
          lng: "",
          oppervlakte: "",
          bouwjaar: "",
          huisnummer: "",
          toevoeging: "",
          postcode: "",
        },
        selectedExtras: [],
        notes: [],
        appliedDiscounts: [],
        finalPrice: 0,
        passcode: null,
        priceDetails: {
          VAT: 0,
          basePrice: 0,
          discountedPrice: 0,
          totalIncludingVAT: 0,
        },
        status: "new",
        signature: null,
        signedAt: null,
        sentAt: null,
        createdAt: null,
        updatedAt: null,
      });
      toggleModal();
    } catch (error) {
      console.error("Error adding lead:", error);
      alert("Fout bij het toevoegen van de lead.");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h3>Leads Beheer</h3>
        <Button
          color="primary"
          onClick={() => {
            setNewLead(defaultLead);
            toggleModal();
          }}
        >
          Nieuwe Lead
        </Button>
      </div>
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Naam</th>
                    <th>Email</th>
                    <th>Adres</th>
                    <th>Agent</th>
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {leads.length === 0 && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Er zijn geen leads beschikbaar.
                      </td>
                    </tr>
                  )}
                  {leads.map((lead) => (
                    <tr key={lead.id}>
                      <td>{moment(lead.createdAt).format("lll")}</td>
                      <td>
                        {lead.customerDetails.firstName}{" "}
                        {lead.customerDetails.lastName}
                      </td>
                      <td>{lead.customerDetails.email}</td>
                      <td>
                        {lead.customerDetails.straat}{" "}
                        {lead.customerDetails.huisnummer}{" "}
                        {lead.customerDetails.toevoeging},{" "}
                        {lead.customerDetails.plaats}
                      </td>
                      <td>
                        {agents.find((agent) => agent.id === lead.agent)
                          ?.personalInfo.firstName || "Geen"}
                      </td>
                      <td>
                        <Button
                          color="info"
                          size="sm"
                          onClick={() => {
                            setNewLead(lead);
                            toggleEditModal();
                          }}
                        >
                          Bewerken
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => handleArchiveLead(lead.id)}
                        >
                          Verwijder
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Nieuwe Lead</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Voornaam</Label>
              <Input
                type="text"
                value={newLead.customerDetails.firstName}
                onChange={(e) =>
                  setNewLead((prev) => ({
                    ...prev,
                    customerDetails: {
                      ...prev.customerDetails,
                      firstName: e.target.value,
                    },
                  }))
                }
              />
            </Col>
            <Col>
              <Label>Achternaam</Label>
              <Input
                type="text"
                value={newLead.customerDetails.lastName}
                onChange={(e) =>
                  setNewLead((prev) => ({
                    ...prev,
                    customerDetails: {
                      ...prev.customerDetails,
                      lastName: e.target.value,
                    },
                  }))
                }
              />
            </Col>
          </Row>
          <Label className="mt-2">Email</Label>
          <Input
            type="email"
            value={newLead.customerDetails.email}
            onChange={(e) =>
              setNewLead((prev) => ({
                ...prev,
                customerDetails: {
                  ...prev.customerDetails,
                  email: e.target.value,
                },
              }))
            }
          />
          <Label className="mt-2">Telefoon</Label>
          <Input
            type="text"
            value={newLead.customerDetails.phone}
            onChange={(e) =>
              setNewLead((prev) => ({
                ...prev,
                customerDetails: {
                  ...prev.customerDetails,
                  phone: e.target.value,
                },
              }))
            }
          />
          <Row>
            <Col>
              <Label>Postcode</Label>
              <Input
                type="text"
                value={newLead.customerDetails.postcode}
                onChange={(e) =>
                  setNewLead((prev) => ({
                    ...prev,
                    customerDetails: {
                      ...prev.customerDetails,
                      postcode: e.target.value,
                    },
                  }))
                }
              />
              <Button
                color="info"
                size="sm"
                className="mt-2"
                onClick={handleAddressSearch}
              >
                Adres Ophalen
              </Button>
            </Col>
            <Col>
              <Label>Huisnummer</Label>
              <Input
                type="text"
                value={newLead.customerDetails.huisnummer}
                onChange={(e) =>
                  setNewLead((prev) => ({
                    ...prev,
                    customerDetails: {
                      ...prev.customerDetails,
                      huisnummer: e.target.value,
                    },
                  }))
                }
              />
            </Col>
            <Col xs="12">
              {newLead.customerDetails?.straat && (
                <div className="address-details">
                  {newLead.customerDetails?.straat}{" "}
                  {newLead.customerDetails?.huisnummer},{" "}
                  {newLead.customerDetails?.plaats}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Agent</Label>
              <Input
                type="select"
                value={newLead.agent || ""}
                onChange={(e) =>
                  setNewLead((prev) => ({
                    ...prev,
                    agent: e.target.value,
                  }))
                }
              >
                <option value="">Selecteer een agent...</option>
                {agents
                  // .sort((a, b) => b.role - a.role)
                  // // .filter((a) => a.role === "agent")
                  .map((agent) => {
                    // console.log(agent);
                    return (
                      <option key={agent.id} value={agent.id}>
                        {agent.personalInfo?.firstName}{" "}
                        {agent.personalInfo?.lastName} | {agent.role}
                      </option>
                    );
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="mt-2">Notitie</Label>
              <Input
                type="textarea"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Voeg een notitie toe..."
              />
              <Button
                color="info"
                size="sm"
                className="mt-2"
                onClick={handleAddNote}
              >
                Notitie Toevoegen
              </Button>
              {newLead.notes.length > 0 && (
                <div className="mt-3">
                  <h5>Notities:</h5>
                  <ul>
                    {newLead.notes.map((note, index) => (
                      <li key={index}>
                        <strong>
                          {moment(convertToDate(note.createdAt)).format("lll")}:
                        </strong>{" "}
                        {note.content}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {newLead.id ? (
            <Button color="primary" onClick={handleAddLead}>
              Opslaan
            </Button>
          ) : (
            <Button color="primary" onClick={handleAddLead}>
              Toevoegen
            </Button>
          )}

          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdminLeadsPage;
