import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

const PhaseOneModal = ({ isOpen, onClose, onSave, lead }) => {
  const [formData, setFormData] = useState({
    appointmentDate: "",
    appointmentTime: "",
    notes: "",
    type: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    lead.status = "appointment";
    lead.saleAfspraak = {
      datum: formData.appointmentDate,
      tijd: formData.appointmentTime,
      type: formData.type,
      opmerkingen: formData.notes,
      agent: null,
    };
    onSave(lead);
  };
  return (
    <Modal isOpen={isOpen} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>Plan een Afspraak</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <Row>
                <Col xs={12}>
                  <h3>Persoonlijke gegevens</h3>
                </Col>
                <Col>
                  <div className="customer-info">
                    <div className="info-line">
                      <span>Naam:</span> {lead?.customerDetails.firstName}{" "}
                      {lead?.customerDetails.lastName}
                      <br />
                      <span>Telefoonnummer:</span> {lead?.customerDetails.phone}
                      <br />
                      <span>E-mailadres:</span> {lead?.customerDetails.email}
                      <br />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="customer-info">
                    <div className="info-line">
                      <span>Adres:</span>
                      {lead?.customerDetails.straat}{" "}
                      {lead?.customerDetails.huisnummer}
                      <br />
                      {lead?.customerDetails.postcode}{" "}
                      {lead?.customerDetails.plaats}
                      <br />
                      {lead?.customerDetails.provincie}
                      <br />
                    </div>
                  </div>
                </Col>
              </Row>
              <h5 className="mt-4">Bekijk locatie</h5>
              <a
                target="_blank"
                href={`https://maps.google.com/?q=${lead?.customerDetails.straat}+${lead?.customerDetails.huisnummer}+${lead?.customerDetails.postcode}+${lead?.customerDetails.plaats}`}
              >
                Google maps
              </a>

              {lead?.notes.length > 0 && (
                <div className="notes-block-lead">
                  {/* <h5 className="mt-4">Notities</h5> */}
                  <h5>Notities</h5>

                  {lead.notes.map((a, i) => {
                    // console.log(a);
                    return (
                      <div key={i}>
                        <div className="title">
                          {a.title} {a.content}
                        </div>
                        <div className="description">{a.description}</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Col>
            <Col>
              <Form>
                <FormGroup>
                  <Label for="appointmentDate">Afspraakdatum</Label>
                  <Input
                    type="date"
                    name="appointmentDate"
                    id="appointmentDate"
                    value={formData.appointmentDate}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="appointmentTime">Afspraaktijd</Label>
                  <Input
                    type="time"
                    name="appointmentTime"
                    id="appointmentTime"
                    value={formData.appointmentTime}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notes">Opmerkingen</Label>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    value={formData.notes}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notes">Type afspraak</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    value={formData.type}
                    onChange={handleChange}
                  >
                    <option>Maak een keuze</option>
                    <option value={"Belafspraak"}>Belafspraak</option>
                    <option value={"Afspraak op locatie"}>
                      Afspraak op locatie
                    </option>
                    <option value={"Online video afspraak"}>
                      Online video afspraak
                    </option>
                  </Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Opslaan
        </Button>
        <Button color="secondary" onClick={onClose}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PhaseOneModal;
