import React from "react";
import { Col, Row } from "reactstrap";

const PackageCard = ({ packageItem, isSelected, onSelect, onOpenModal }) => {
  return (
    <div className={`package-card ${isSelected ? "selected" : ""}`}>
      <Row>
        <Col xs={5}>
          {packageItem.images && packageItem.images.length > 0 && (
            <img
              src={packageItem.images[0]}
              alt={packageItem.naam}
              className="package-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/fallback-image.png"; // Path to fallback image
              }}
            />
          )}
        </Col>
        <Col>
          <div className="package-card-title">
            <div>
              <h3 style={{ fontSize: 20 }}>{packageItem.naam}</h3>
              <p>
                <strong>Prijs:</strong> €
                {packageItem.prijs ? packageItem.prijs.toLocaleString() : "N/A"}{" "}
                {packageItem.solar && <small>per paneel</small>}
              </p>
            </div>
          </div>
        </Col>
      </Row>

      {/* <div
        className="package-description"
        dangerouslySetInnerHTML={{ __html: packageItem.beschrijving }}
      ></div> */}

      {/* <p>
        <strong>Aantal producten:</strong> {packageItem.producten?.length || 0}
      </p> */}
      <div className="package-buttons">
        <button onClick={onSelect}>
          {isSelected ? "Geselecteerd" : "Selecteer"}
        </button>
        <button className="secondary" onClick={onOpenModal}>
          Meer Info
        </button>
      </div>
    </div>
  );
};

export default PackageCard;
