import jsPDF from "jspdf";
import "jspdf-autotable";
import { logoUrl } from "../../Logo";

const SchouwReport = async (lead) => {
  const schouwDetails = lead?.schouwDetails;
  if (!schouwDetails) {
    console.error("No schouw details provided");
    return;
  }

  const doc = new jsPDF();

  // Logo at the top
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20);

  // Title
  doc.setFont("Roboto", "bold");
  doc.setFontSize(22);
  doc.text("Schouwrapport", 14, 40);

  // Klantgegevens Section
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Klantgegevens", 14, 50);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(12);
  const customerInfo = [
    [
      "Naam",
      `${lead.customerDetails?.firstName || ""} ${
        lead.customerDetails?.lastName || ""
      }`,
    ],
    [
      "Adres",
      `${lead.customerDetails?.straat || ""} ${
        lead.customerDetails?.huisnummer || ""
      }`,
    ],
    [
      "Postcode en Plaats",
      `${lead.customerDetails?.postcode || ""} ${
        lead.customerDetails?.plaats || ""
      }`,
    ],
    ["E-mailadres", lead.customerDetails?.email || "N/A"],
    ["Telefoonnummer", lead.customerDetails?.phone || "N/A"],
  ];
  doc.autoTable({
    startY: 55,
    head: [["Eigenschap", "Waarde"]],
    body: customerInfo,
    theme: "grid",
  });

  // Schouwer Section
  let currentY = doc.lastAutoTable.finalY + 10;
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Schouwer Gegevens", 14, currentY);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(12);
  const schouwerInfo = [
    [
      "Naam",
      `${schouwDetails?.schouwer?.personalInfo.firstName} ${schouwDetails?.schouwer?.personalInfo.lastName}` ||
        "N/A",
    ],
    ["E-mailadres", schouwDetails?.schouwer?.email || "N/A"],
    [
      "Telefoonnummer",
      schouwDetails?.schouwer?.personalInfo?.phoneNumber || "N/A",
    ],
  ];
  doc.autoTable({
    startY: currentY + 5,
    head: [["Eigenschap", "Waarde"]],
    body: schouwerInfo,
    theme: "grid",
  });

  // Algemene Informatie Section
  currentY = doc.lastAutoTable.finalY + 10;
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Algemene Informatie", 14, currentY);

  const generalInfo = [
    ["Schouwdatum", schouwDetails?.schouwDate || "N/A"],
    ["Schouwtijd", schouwDetails?.schouwTime || "N/A"],
    ["Type woning", schouwDetails?.typeOfHouse || "N/A"],
    ["Bouwjaar", schouwDetails?.buildingYear || "N/A"],
    ["Isolatie", schouwDetails?.insulation?.join(", ") || "Geen"],
    ["Type dak", schouwDetails?.roofType || "N/A"],
    ["Dakbedekking", schouwDetails?.roofMaterial || "N/A"],
  ];
  doc.autoTable({
    startY: currentY + 5,
    head: [["Eigenschap", "Waarde"]],
    body: generalInfo,
    theme: "grid",
  });

  // Gekozen Producten Section
  currentY = doc.lastAutoTable.finalY + 10;
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Gekozen Producten", 14, currentY);

  const selectedProducts = [["Product", lead?.selectedProduct?.naam || "N/A"]];
  if (lead.selectedExtras.length > 0) {
    lead.selectedExtras.map((a) =>
      selectedProducts.push(["Extra product", a.name])
    );
  }
  doc.autoTable({
    startY: currentY + 5,
    head: [["Eigenschap", "Waarde"]],
    body: selectedProducts,
    theme: "grid",
  });

  //   // Algemene Informatie Section
  //   doc.setFont("Roboto", "bold");
  //   doc.setFontSize(16);
  //   doc.text("Algemene Informatie", 14, 50);

  //   doc.setFont("Roboto", "normal");
  //   doc.setFontSize(12);
  //   const generalInfo = [
  //     ["Schouwdatum", schouwDetails?.schouwDate || "N/A"],
  //     ["Schouwtijd", schouwDetails?.schouwTime || "N/A"],
  //     ["Type woning", schouwDetails?.typeOfHouse || "N/A"],
  //     ["Bouwjaar", schouwDetails?.buildingYear || "N/A"],
  //     ["Isolatie", schouwDetails?.insulation?.join(", ") || "Geen"],
  //     ["Type dak", schouwDetails?.roofType || "N/A"],
  //     ["Dakbedekking", schouwDetails?.roofMaterial || "N/A"],
  //   ];
  //   doc.autoTable({
  //     startY: 55,
  //     head: [["Eigenschap", "Waarde"]],
  //     body: generalInfo,
  //     theme: "grid",
  //   });
  doc.addPage();
  // Technische Gegevens Section
  currentY = 20;
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Technische Gegevens", 14, currentY);

  const technicalInfo = [
    [
      "Gemiddeld elektriciteitsverbruik",
      `${schouwDetails?.electricityUsage || "N/A"} kWh/jaar`,
    ],
    ["Aantal zonnepanelen", schouwDetails?.solarPanels || "N/A"],
    ["Opwekking", `${schouwDetails?.opwekking || "N/A"} kWh/jaar`],
    ["Huidige omvormer", schouwDetails?.inverterNow || "N/A"],
  ];
  doc.autoTable({
    startY: currentY + 5,
    head: [["Eigenschap", "Waarde"]],
    body: technicalInfo,
    theme: "grid",
  });

  // Installatie Details Section
  currentY = doc.lastAutoTable.finalY + 10;
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Installatie Details", 14, currentY);

  const installationDetails = [
    ["Locatie van de batterij", schouwDetails?.batteryLocation || "N/A"],
    ["Kabeltraject batterij", schouwDetails?.kabelTrajectBattery || "N/A"],
  ];
  doc.autoTable({
    startY: currentY + 5,
    head: [["Eigenschap", "Waarde"]],
    body: installationDetails,
    theme: "grid",
  });
  // Footer
  doc.setFontSize(10);
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );
  // Photos Section in a Grid
  doc.addPage();
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Foto's", 14, 20);

  let photoY = 30; // Initial Y position for the grid
  let photoX = 14; // Initial X position for the grid
  const photoWidth = 80; // Width of each photo
  const photoHeight = 60; // Height of each photo
  const maxPhotosPerRow = 2; // Max 2 photos per row
  let photoCount = 0;

  for (let i = 0; i < schouwDetails?.photos.filter((a) => a.url).length; i++) {
    const photo = schouwDetails?.photos[i];
    if (photo.url) {
      // Add the photo name below the image
      doc.setFontSize(10);
      doc.text(photo.name || "Foto", photoX, photoY + photoHeight + 5);

      try {
        const img = await fetch(photo.url)
          .then((res) => res.blob())
          .then(
            (blob) =>
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.readAsDataURL(blob);
              })
          );

        // Add the image to the PDF
        doc.addImage(
          img,
          "JPEG",
          photoX,
          photoY,
          photoWidth,
          photoHeight,
          undefined,
          "FAST"
        );
      } catch {
        doc.setFont("Roboto", "italic");
        doc.text(
          "Afbeelding niet beschikbaar",
          photoX,
          photoY + photoHeight / 2
        );
      }
    }

    // Update positions for the next photo
    photoX += photoWidth + 20; // Move to the next column
    photoCount++;

    // Move to the next row if the row is full
    if (photoCount === maxPhotosPerRow) {
      photoX = 14; // Reset X to the start of the row
      photoY += photoHeight + 30; // Move Y down for the next row
      photoCount = 0;
    }

    // Add a new page if the current page height is exceeded
    if (photoY + photoHeight > 280) {
      doc.addPage();
      photoY = 30; // Reset Y position on the new page
      photoX = 14; // Reset X position on the new page
      photoCount = 0;
    }
  }
  // Footer
  doc.setFontSize(10);
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );

  // Signatures Section on a New Page
  doc.addPage();
  doc.setFont("Roboto", "bold");
  doc.setFontSize(16);
  doc.text("Handtekeningen", 14, 20);

  // Description of what the signatures are for
  doc.setFont("Roboto", "normal");
  doc.setFontSize(12);
  doc.text(
    "Ondertekening bevestigt dat de schouw is uitgevoerd en de informatie correct is.",
    14,
    30
  );

  // Schouwer Signature
  doc.setFont("Roboto", "normal");
  doc.text("Schouwer:", 14, 50);
  if (schouwDetails.schouwerSignature) {
    doc.addImage(schouwDetails.schouwerSignature, "JPEG", 43, 36, 35, 15);
  }
  doc.line(14, 55, 100, 55); // Line for schouwer signature

  // Client Signature
  doc.text("Klant:", 14, 70);
  if (schouwDetails.clientSignature) {
    doc.addImage(schouwDetails.clientSignature, "JPEG", 39, 57, 35, 15);
  }
  doc.line(14, 75, 100, 75); // Line for client signature

  // Footer
  doc.setFontSize(10);
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );

  const pdfBlob = new Blob([doc.output("blob")], {
    type: "application/pdf",
  });

  return pdfBlob;
};

export default SchouwReport;
