import jsPDF from "jspdf";
import "jspdf-autotable";
import { backgroundImage } from "../../Assets/pdf/Offerte1/Background";
import { logoUrl } from "../../Logo";
import { Signature } from "../../Assets/Signature";
import "../../Assets/fonts/Roboto-regular";
import "../../Assets/fonts/Roboto-bold";
import { imageUrlToBase64 } from "../../utils/getBase64";
import { htmlToText } from "html-to-text";
import { PDFDocument } from "pdf-lib";

/**
 * Renders an introductory page with background, main title, and brief client + company info.
 */
function renderIntroPage(doc, offerte) {
  const createdAt = offerte.createdAt?.seconds
    ? offerte.createdAt.seconds * 1000
    : offerte.sentAt;
  let sendAt = null;

  if (offerte.sentAt) {
    sendAt = offerte.sentAt?.seconds
      ? offerte.sentAt.seconds * 1000
      : offerte.sentAt;
  }

  const createdDate = new Date(createdAt).toLocaleDateString("nl-NL");
  const sentDate = sendAt ? new Date(sendAt).toLocaleDateString("nl-NL") : null;

  doc.setFont("Roboto", "normal");

  // Background image
  if (backgroundImage) {
    doc.addImage(backgroundImage, "JPEG", 0, 0, 210, 297); // A4 size in mm
  }

  // Title
  doc.setFontSize(28);
  doc.setFont("Roboto", "bold");
  doc.text("Uw persoonlijke voorstel", 14, 60);

  // Subtitle
  doc.setFontSize(18);
  doc.setFont("Roboto", "normal");
  const splitTitle = doc.splitTextToSize(offerte.selectedProduct.naam, 140);
  doc.text(splitTitle || "N/A", 14, 70);

  // Client details
  doc.setFontSize(14);
  const lineSpacing = 6;

  doc.setFont("Roboto", "bold");
  doc.text("Opgesteld voor:", 14, 90);
  doc.setFont("Roboto", "normal");

  doc.text(
    `${offerte.customerDetails?.firstName || ""} ${
      offerte.customerDetails?.lastName || ""
    }`,
    14,
    90 + lineSpacing
  );
  doc.text(
    `${offerte.customerDetails?.straat || "N/A"} ${
      offerte.customerDetails?.huisnummer || ""
    }`,
    14,
    90 + lineSpacing * 2
  );
  doc.text(
    `${offerte.customerDetails?.postcode || ""} ${
      offerte.customerDetails?.plaats || ""
    }`,
    14,
    90 + lineSpacing * 3
  );
  doc.text(offerte.customerDetails?.email || "N/A", 14, 90 + lineSpacing * 4);

  // Company details (right side)
  const rightColumnX = 105;
  doc.setFont("Roboto", "bold");
  doc.text("Gemaakt door:", rightColumnX, 90);
  doc.setFont("Roboto", "normal");
  doc.text("Thuisbatterij Experts", rightColumnX, 90 + lineSpacing);
  doc.text(
    `Datum: ${sentDate || createdDate}`,
    rightColumnX,
    90 + lineSpacing * 2
  );
  doc.text(
    `Offertenr.: ${offerte.offerteNr || "N/A"}`,
    rightColumnX,
    90 + lineSpacing * 3
  );

  // New page for the introduction letter
  doc.addPage();
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20); // Logo
  doc.setFontSize(14);
  doc.setFont("Roboto", "normal");

  const defaultIntro = `
Beste ${offerte.customerDetails?.firstName || ""} ${
    offerte.customerDetails?.lastName || ""
  },

Naar aanleiding van uw aanvraag heb ik het genoegen u een persoonlijk aanbod te doen voor een thuisbatterijsysteem. Het voorgestelde systeem, inclusief de ${
    offerte.selectedProduct.naam
  }, is zorgvuldig samengesteld om perfect aan uw behoeften te voldoen en biedt een optimale combinatie van energieopslag, onafhankelijkheid en duurzaamheid.

Met dit systeem maakt u een bewuste keuze voor meer controle over uw energieverbruik, lagere kosten en een positieve impact op het milieu. In de bijlage vindt u alle details over het systeem, de installatie en de voordelen die u kunt verwachten.

Wij staan uiteraard klaar om eventuele vragen te beantwoorden en u verder te begeleiden in dit traject.
`;

  const textContent = offerte.inleiding || defaultIntro;
  doc.text(textContent.trim(), 14, 40, { maxWidth: 180 });

  // Footer
  doc.setFontSize(12);
  doc.setFont("Roboto", "normal");
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    280,
    { align: "center" }
  );
}

/**
 * Renders a page explaining general information about the product (Over uw aanvraag).
 */
function renderOverUwAanvraagPage(doc, offerte) {
  doc.addPage();

  // Logo
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20);

  const greenColor = "#18ba6a";
  const normalFontSize = 12;
  const headerFontSize = 21;
  const subHeaderFontSize = 14;

  // Title
  doc.setFont("Roboto", "bold");
  doc.setFontSize(headerFontSize);
  doc.text("Over uw aanvraag", 14, 40);

  // First row, left column
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Thuisbatterij", 14, 55);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Een thuisbatterij zoals de ${offerte.selectedProduct.naam} is meer dan alleen een opslagruimte voor energie; het is een sleutel naar energiebeheer. Door de opgewekte zonneenergie op te slaan, kunt u deze gebruiken wanneer u die het meest nodig heeft. Dit betekent minder afhankelijkheid van het energienet en een optimale benutting van uw zonnepanelen. Met een ${offerte.selectedProduct.naam} kunt u rekenen op voldoende opslag om uw huishouden efficiënt van energie te voorzien, zelfs tijdens piekuren of stroomuitval.`,
    14,
    60,
    { maxWidth: 90 }
  );

  // First row, right column
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Onafhankelijkheid", 110, 55);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Met een thuisbatterij bent u minder afhankelijk van energieleveranciers en schommelende energieprijzen. De ${offerte.selectedProduct.naam} biedt u de mogelijkheid om uw eigen opgewekte energie slim te gebruiken, wanneer dat het meest voordelig is. Dit geeft u de vrijheid en zekerheid om uw huishouden duurzamer en zelfstandiger te maken, zelfs bij veranderingen in het energielandschap.`,
    110,
    60,
    { maxWidth: 90 }
  );

  // Second row, left column
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Uw duurzame verdienmodel", 14, 135);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `De investering in een thuisbatterij is een stap richting een duurzaam verdienmodel. U bespaart aanzienlijk op energiekosten door meer eigen zonne-energie te gebruiken in plaats van deze terug te leveren aan het net tegen lage tarieven. Met de ${offerte.selectedProduct.naam} profiteert u van een efficiënt systeem dat niet alleen bijdraagt aan kostenbesparing, maar ook de waarde van uw woning verhoogt.`,
    14,
    140,
    { maxWidth: 90 }
  );

  // Second row, right column
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Uw rendement", 110, 135);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Het rendement van een thuisbatterij is indrukwekkend. Dankzij de ${offerte.selectedProduct.naam} kunt u uw eigen zonne-energie optimaal benutten, wat resulteert in lagere energiekosten en een korte terugverdientijd. Bovendien biedt dit systeem een oplossing voor stijgende netwerkkosten en dalende teruglevertarieven, waardoor u jaar na jaar blijft besparen. Zo haalt u het maximale uit uw duurzame investering.`,
    110,
    140,
    { maxWidth: 90 }
  );

  // Weetje box
  const weetjeBoxX = 14;
  const weetjeBoxY = 200;
  const weetjeBoxWidth = 182;
  const weetjeBoxHeight = 26;
  doc.setFillColor("#fcf6dc");
  doc.rect(weetjeBoxX, weetjeBoxY, weetjeBoxWidth, weetjeBoxHeight, "F");

  doc.setFont("Roboto", "bold");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text("Weetje:", weetjeBoxX + 4, weetjeBoxY + 7);

  doc.setFont("Roboto", "normal");
  doc.text(
    `Onderzoek toont aan dat huishoudens met een thuisbatterij zoals de 
${offerte.selectedProduct.naam} gemiddeld 25% minder CO2 uitstoten. 
Door te kiezen voor energieopslag draagt u direct bij aan een duurzamer 
milieu en versterkt u uw impact op een groenere toekomst.`,
    weetjeBoxX + 20,
    weetjeBoxY + 7
  );

  // Footer
  doc.setFont("Roboto", "normal");
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );
}

/**
 * Renders a page describing the main selected product. Returns the page index so we can insert a PDF after it if needed.
 */
async function renderDeProductenPage(doc, offerte) {
  doc.addPage();
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20);

  doc.setFont("Roboto", "bold");
  doc.setFontSize(22);
  doc.text("De Producten", 14, 40);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(14);
  doc.text("Overzicht van de producten in uw offerte", 14, 50);

  const lineSpacing = 6;
  let yPosition = 60;

  // Basic product descriptions (batterij modules, etc.)
  doc.setFont("Roboto", "bold");
  doc.setFontSize(14);
  doc.text("Batterij Modules", 14, yPosition);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(14);
  doc.text(
    "De kern van het systeem, bestaande uit lithiumijzerfosfaat (LiFePO4) cellen, bekend om hun veiligheid en lange levensduur.",
    14,
    yPosition + lineSpacing,
    { maxWidth: 90 }
  );

  doc.setFont("Roboto", "bold");
  doc.text("Battery Management Unit (BMU)", 14, yPosition + 35);

  doc.setFont("Roboto", "normal");
  doc.text(
    "Deze eenheid beheert en bewaakt de prestaties van de batterijmodules, zorgt voor een optimale werking en verlengt de levensduur van het systeem.",
    14,
    yPosition + 41,
    { maxWidth: 90 }
  );

  // Attempt to load product image
  const imageX = 120;
  const imageY = 40;
  const imageWidth = 75;
  const imageHeight = 75;
  let base64Image = null;

  // try {
  //   const imageUrl = offerte.selectedProduct?.images?.[0];
  //   console.log("Loading image from URL:", imageUrl); // Debug the URL
  //   if (imageUrl) {
  //     base64Image = await imageUrlToBase64(imageUrl);
  //     console.log("Base64 conversion result:", base64Image);
  //   }
  // } catch (err) {
  //   console.error("Failed to load/convert product image", err);
  //   // Optionally, you can set base64Image to a default/fallback value here
  //   base64Image = null;
  // }

  // if (base64Image && base64Image.base64Image) {
  //   try {
  //     doc.addImage(
  //       base64Image.base64Image,
  //       "JPEG",
  //       imageX,
  //       imageY,
  //       imageWidth,
  //       imageHeight
  //     );

  //   } catch (err) {
  //     console.error("Failed to add image to PDF", err);
  //     doc.setFont("Roboto", "italic");
  //     doc.text("Afbeelding niet beschikbaar", imageX + 10, imageY + 30);
  //   }
  // } else {
  //   // Fallback: if the image conversion fails, show a placeholder text.
  //   doc.setFont("Roboto", "italic");
  //   doc.text("Afbeelding niet beschikbaar", imageX + 10, imageY + 30);
  // }
  doc.setFont("Roboto", "italic");
  doc.text("Afbeelding niet beschikbaar", imageX + 10, imageY + 30);
  // Adjust yPosition so subsequent text is rendered below the image
  yPosition = Math.max(yPosition, imageY + imageHeight + 10); // e.g. 40+75+10 = 125

  // Now continue with the next section
  doc.setFont("Roboto", "bold");
  doc.text("Communicatiemodule", 14, yPosition);

  doc.setFont("Roboto", "normal");
  doc.text(
    "Voorzien van een ingebouwde Wi-Fi-module, waardoor u via een app de status van uw systeem in real-time kunt monitoren en updates op afstand kunt ontvangen.",
    14,
    yPosition + lineSpacing,
    { maxWidth: 182 }
  );

  yPosition += 20;
  doc.setFont("Roboto", "bold");
  doc.text("Installatieaccessoires", 14, yPosition);
  doc.setFont("Roboto", "normal");
  doc.text(
    "Alle benodigde kabels, bevestigingsmaterialen en handleidingen voor een veilige en efficiënte installatie van het systeem. Afhankelijk van uw specifieke behoeften en de configuratie van het systeem, kunnen er extra componenten of accessoires worden toegevoegd om de functionaliteit en prestaties te optimaliseren.",
    14,
    yPosition + lineSpacing,
    { maxWidth: 182 }
  );

  // Footer
  doc.setFontSize(10);
  doc.setFont("Roboto", "normal");
  doc.text(
    "www.thuisbatterijexperts.nl    +31 (085) 250 2232    info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );

  // Return the page index so we can insert the product PDF(s) right after this page
  return doc.getNumberOfPages();
}

/**
 * Renders the "Extra Producten" section on new pages.
 */
export const addExtraProductsSection = async (doc, offerte) => {
  // Start a new page for Extra Products
  doc.addPage();

  // Title for the section
  doc.setFont("Roboto", "bold");
  doc.setFontSize(20);
  doc.text("Extra Producten", 14, 20);

  const lineSpacing = 6;
  const imageWidth = 50;
  const imageHeight = 50;
  let yPosition = 40;

  const extras = offerte.selectedExtras || [];
  for (let i = 0; i < extras.length; i++) {
    const extra = extras[i];

    // Check if we need a new page
    if (yPosition > 270) {
      doc.addPage();
      yPosition = 30; // Reset Y position for the new page
    }
    doc.setFontSize(14);

    // Image on the left
    if (extra.images?.length > 0) {
      try {
        const base64Image = await imageUrlToBase64(extra.images[0]);
        doc.addImage(
          base64Image.base64Image,
          "JPEG",
          14,
          yPosition,
          imageWidth,
          imageHeight
        );
      } catch (err) {
        console.warn(`Afbeelding kon niet worden geladen: ${extra.name}`);
        doc.setFont("Roboto", "italic");
        doc.text(
          "Afbeelding niet beschikbaar",
          14,
          yPosition + imageHeight / 2
        );
      }
    } else {
      doc.setFont("Roboto", "italic");
      doc.text("Afbeelding niet beschikbaar", 14, yPosition + imageHeight / 2);
    }

    // Title and description on the right
    const textXPosition = 14 + imageWidth + 10; // Space after the image
    doc.setFont("Roboto", "bold");
    doc.setFontSize(14);
    doc.text(extra.name || "Onbekend product", textXPosition, yPosition + 5);

    doc.setFont("Roboto", "normal");
    doc.setFontSize(12);
    const description = extra.description
      ? htmlToText(extra.description, {
          wordwrap: 0,
          preserveNewlines: true,
        })
      : "Geen beschrijving beschikbaar.";
    const descriptionLines = doc.splitTextToSize(description, 120);
    doc.text(descriptionLines, textXPosition, yPosition + 15);

    // Adjust yPosition for the next product
    yPosition +=
      Math.max(imageHeight, descriptionLines.length * lineSpacing) + 20;

    // Draw a border below the product (skip the last one)
    if (i < extras.length - 1) {
      doc.setDrawColor(200); // Light gray
      doc.setLineWidth(0.5);
      doc.line(14, yPosition, 200, yPosition);
      yPosition += 10;
    }
  }

  // Return the page index so we can insert extra PDF(s) right after these pages
  return doc.getNumberOfPages();
};

/**
 * Renders the "Het aanbod" page with a price table and signature lines.
 */
function renderHetAanbodPage(doc, offerte) {
  doc.addPage();
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20);

  doc.setFont("Roboto", "bold");
  doc.setFontSize(20);
  const top = 40;
  doc.text("Het aanbod", 14, top);

  doc.setFontSize(10);
  doc.setFont("Roboto", "normal");
  doc.text("Thuisbatterij Experts", 140, top + 15);
  doc.text("Parnassusweg 101 103", 140, top + 20);
  doc.text("1077 DE Amsterdam", 140, top + 25);
  doc.text("KvK: 80640842", 140, top + 30);
  doc.text("Btw.nr.: NL003470826B34", 140, top + 35);

  // Client details
  doc.text(
    `${offerte.customerDetails?.firstName || ""} ${
      offerte.customerDetails?.lastName || ""
    }`,
    14,
    top + 15
  );
  doc.text(
    `${offerte.customerDetails?.straat || "N/A"} ${
      offerte.customerDetails?.huisnummer || ""
    }`,
    14,
    top + 20
  );
  doc.text(
    `${offerte.customerDetails?.postcode || ""} ${
      offerte.customerDetails?.plaats || ""
    }`,
    14,
    top + 25
  );
  doc.text(offerte.customerDetails?.email || "N/A", 14, top + 30);

  const createdAt = offerte.createdAt?.seconds
    ? offerte.createdAt.seconds * 1000
    : offerte.sentAt;
  let sendAt = null;
  if (offerte.sentAt) {
    sendAt = offerte.sentAt?.seconds
      ? offerte.sentAt.seconds * 1000
      : offerte.sentAt;
  }
  const createdDate = new Date(createdAt).toLocaleDateString("nl-NL");
  const sentDate = sendAt ? new Date(sendAt).toLocaleDateString("nl-NL") : null;

  doc.text(`Offertedatum: ${sentDate || createdDate}`, 14, top + 40);
  doc.text(`Offertenr.: ${offerte.offerteNr || "N/A"}`, 14, top + 45);

  // Table header
  doc.setFontSize(10);
  doc.setFillColor(24, 186, 106); // Green
  doc.rect(14, top + 50, 182, 10, "F");
  doc.setTextColor(255, 255, 255);
  doc.text("Naam", 16, top + 56);
  doc.text("Aantal", 100, top + 56);
  doc.text("Subtotaal", 165, top + 56);

  doc.setTextColor(0, 0, 0);
  let yPos = top + 65;
  const lineSpacing = 6;

  // Main product
  if (offerte.selectedProduct) {
    const productName = offerte.selectedProduct.naam || "Onbekend";
    const productDescription = htmlToText(
      offerte.selectedProduct.beschrijving || "",
      {
        wordwrap: 90,
      }
    );

    doc.text(productName, 22, yPos);
    const descriptionLines = doc.splitTextToSize(productDescription, 150);
    descriptionLines.forEach((line, i) => {
      doc.text(line, 22, yPos + lineSpacing * (i + 1));
    });

    console.log(offerte.selectedProduct);
    if (offerte.selectedProduct.solar) {
      doc.text(`${offerte.selectedProduct.solarPanelCount}x`, 14, yPos, {
        align: "left",
      });
    } else {
      doc.text("1", 100, yPos, { align: "right" });
    }
    if (offerte.selectedProduct.solar) {
      doc.text(
        `€${parseFloat(
          offerte.selectedProduct.prijs *
            offerte.selectedProduct.solarPanelCount
        ).toFixed(2)}`,
        182,
        yPos,
        { align: "right" }
      );
    } else {
      doc.text(
        `€${parseFloat(offerte.selectedProduct.prijs).toFixed(2)}`,
        182,
        yPos,
        { align: "right" }
      );
    }
    // doc.text(
    //   `€${parseFloat(offerte.selectedProduct.prijs || 0).toFixed(2)}`,
    //   182,
    //   yPos,
    //   { align: "right" }
    // );

    yPos += lineSpacing * (descriptionLines.length + 1.5);
  }

  // Extra products
  (offerte.selectedExtras || []).forEach((extra) => {
    const qty = extra.type === "quantity" ? extra.quantity || 1 : 1;
    doc.text(extra.name || "Onbekend", 22, yPos);
    doc.text(`${qty}x`, 15, yPos, { align: "left" });

    const subtotal = (qty * parseFloat(extra.price || 0)).toFixed(2);
    doc.text(`€${subtotal}`, 182, yPos, { align: "right" });
    yPos += lineSpacing;
  });

  doc.rect(14, yPos - 1.5, 182, 0);
  yPos += 5;

  // Subtotals, discount, etc.
  doc.setFont("Roboto", "bold");
  doc.text("Subtotaal excl. btw", 160, yPos, { align: "right" });
  doc.text(
    `€${parseFloat(offerte.priceDetails.basePrice || 0).toFixed(2)}`,
    182,
    yPos,
    { align: "right" }
  );

  if (offerte.appliedDiscounts && offerte.appliedDiscounts.length > 0) {
    yPos += lineSpacing;
    doc.text("Korting", 160, yPos, { align: "right" });
    const discountAmount =
      offerte.priceDetails.basePrice - offerte.priceDetails.discountedPrice;
    doc.text(`-€${parseFloat(discountAmount).toFixed(2)}`, 182, yPos, {
      align: "right",
    });

    yPos += lineSpacing;
    doc.text("Totaal excl. btw", 160, yPos, { align: "right" });
    doc.text(
      `€${parseFloat(offerte.priceDetails.discountedPrice).toFixed(2)}`,
      182,
      yPos,
      { align: "right" }
    );
  }

  doc.rect(120, yPos + 1.6, 80, 0);
  yPos += lineSpacing;
  doc.text("Totaal btw 21%", 160, yPos, { align: "right" });
  doc.text(
    `€${parseFloat(offerte.priceDetails.VAT || 0).toFixed(2)}`,
    182,
    yPos,
    { align: "right" }
  );

  yPos += lineSpacing;
  doc.setFontSize(12);
  doc.text("Totaal incl. btw", 160, yPos, { align: "right" });
  doc.text(
    `€${parseFloat(offerte.priceDetails.totalIncludingVAT || 0).toFixed(2)}`,
    182,
    yPos,
    { align: "right" }
  );

  // Payment conditions
  if (offerte.selectedFinancingOption) {
    doc.text("Betalingscondities: ", 14, yPos);
    doc.setFont("Roboto", "normal");
    doc.text(offerte.selectedFinancingOption.name, 14, yPos + 5);
    doc.setFontSize(11);
    doc.setFont("Roboto", "italic");
    doc.text(offerte.selectedFinancingOption.paymentCondition, 14, yPos + 10);
  }

  // Signatures
  yPos += 20;
  doc.setFontSize(12);
  doc.setFont("Roboto", "bold");
  doc.text("Geaccepteerd door:", 14, yPos);

  // Left signature (company)
  yPos += 10;
  doc.setFont("Roboto", "normal");
  doc.text("Thuisbatterij Experts", 14, yPos);
  doc.addImage(Signature, "JPEG", 14, yPos + 3, 60, 20);
  doc.line(14, yPos + 18, 70, yPos + 18);

  // Right signature (customer)
  doc.text(
    `${offerte.customerDetails?.firstName || ""} ${
      offerte.customerDetails?.lastName || "Klant"
    }`,
    110,
    yPos
  );
  if (offerte.status === "accepted" && offerte.signature) {
    doc.addImage(offerte.signature, "JPEG", 120, yPos + 3, 30, 15);
  }
  doc.line(110, yPos + 18, 182, yPos + 18);

  // Dates
  yPos += 25;
  const createdLine = `Datum: ${sentDate || createdDate}`;
  doc.text(createdLine, 14, yPos);

  if (offerte.status === "accepted" && offerte.signedAt?.seconds) {
    const acceptanceDate = new Date(
      offerte.signedAt.seconds * 1000
    ).toLocaleDateString("nl-NL");
    doc.text(`Datum: ${acceptanceDate}`, 110, yPos);
  }

  // Footer
  doc.setFontSize(10);
  doc.setFont("Roboto", "normal");
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );
}

/**
 * Inserts PDF pages right after certain page indexes using pdf-lib.
 * @param {ArrayBuffer} mainPdfBytes - the main PDF (jsPDF result) as array buffer
 * @param {Array} insertQueue - array of { afterPageIndex: number, pdfUrl: string } elements
 * @returns {Promise<Uint8Array>} merged PDF bytes
 */
async function insertPdfPagesAt(mainPdfBytes, insertQueue) {
  if (!insertQueue?.length) return mainPdfBytes;

  const mainPdf = await PDFDocument.load(mainPdfBytes);

  // Insert in descending order to avoid shifting page indexes
  insertQueue.sort((a, b) => b.afterPageIndex - a.afterPageIndex);

  for (const item of insertQueue) {
    const { afterPageIndex, pdfUrl } = item;
    let currentIndex = afterPageIndex;
    try {
      const response = await fetch(pdfUrl);
      const data = await response.arrayBuffer();
      const externalPdf = await PDFDocument.load(data);
      const copiedPages = await mainPdf.copyPages(
        externalPdf,
        externalPdf.getPageIndices()
      );
      // Insert pages after "afterPageIndex"
      // pdf-lib uses 0-based, so if afterPageIndex is 2,
      // it means insert starting at position 2 in pdf-lib's array,
      // which effectively places them after that page.
      for (const page of copiedPages) {
        mainPdf.insertPage(currentIndex, page);
        currentIndex++;
      }
    } catch (err) {
      console.error("Could not insert PDF from URL:", pdfUrl, err);
    }
  }

  return await mainPdf.save();
}

/**
 * Main function to create the Offerte PDF.
 * This version shows how to insert datasheets right after product pages.
 */
const Offerte2 = async (offerte) => {
  const doc = new jsPDF();

  // 1. Always create the Intro page(s)
  renderIntroPage(doc, offerte);

  // We'll store an insertion queue for *any* PDFs that we need to merge.
  const insertQueue = [];

  const introPageCount = doc.getNumberOfPages();

  const productPageArray = offerte.selectedProduct?.productPage || [];
  if (productPageArray.length > 0) {
    // We skip "Over uw aanvraag" and "De Producten"
    // Instead, schedule insertion of productPage PDFs right after the intro.
    for (const pdfUrl of productPageArray) {
      insertQueue.push({
        pdfUrl,
        afterPageIndex: introPageCount,
      });
    }
  } else {
    // Fallback: No productPage PDFs => Render "Over uw aanvraag" & "De Producten"
    renderOverUwAanvraagPage(doc, offerte);
    renderDeProductenPage(doc, offerte);
  }

  // 2. Add "Extra Producten" if any
  if (offerte.selectedExtras?.length > 0) {
    await addExtraProductsSection(doc, offerte);
  }

  // 4. Render "Het Aanbod"
  renderHetAanbodPage(doc, offerte);

  const afterHetAanbodIndex = doc.getNumberOfPages();
  // 4. If the main product has datasheets, insert them after HetAanbod.
  if (offerte.selectedProduct?.datasheets?.length > 0) {
    for (const pdfUrl of offerte.selectedProduct.datasheets) {
      insertQueue.push({
        pdfUrl,
        afterPageIndex: afterHetAanbodIndex,
      });
    }
  }

  // 5. If the extras have PDFs, decide where to insert them.
  // If you also want them AFTER HetAanbod, do this:
  if (offerte.selectedExtras?.length > 0) {
    for (const extra of offerte.selectedExtras) {
      if (extra.pdfs?.length > 0) {
        for (const pdfUrl of extra.pdfs) {
          insertQueue.push({
            pdfUrl,
            afterPageIndex: afterHetAanbodIndex,
          });
        }
      }
    }
  }
  // 7. Output the doc to ArrayBuffer
  const mainPdfBytes = doc.output("arraybuffer");

  // 8. Insert any PDFs we have in insertQueue
  const finalMergedPdfBytes = await insertPdfPagesAt(mainPdfBytes, insertQueue);
  // 4. Convert to Blob and return
  const pdfBlob = new Blob([finalMergedPdfBytes], {
    type: "application/pdf",
  });
  return pdfBlob;
};

export default Offerte2;
