import jsPDF from "jspdf";
import "jspdf-autotable";
import { backgroundImage } from "../../Assets/pdf/Offerte1/Background";
import { logoUrl } from "../../Logo";
import { Signature } from "../../Assets/Signature";
import "../../Assets/fonts/Roboto-regular";
import "../../Assets/fonts/Roboto-bold";
import { imageUrlToBase64 } from "../../utils/getBase64";
import { htmlToText } from "html-to-text";
import { PDFDocument } from "pdf-lib";

const addExtraProductsSection = async (doc, offerte) => {
  // Start a new page for Extra Products
  doc.addPage();

  // Title for the section
  doc.setFont("Roboto", "bold");
  doc.setFontSize(20);
  doc.text("Extra Producten", 14, 20);

  const lineSpacing = 6;
  const imageWidth = 50;
  const imageHeight = 50;
  let yPosition = 40;

  const extras = offerte.selectedExtras || [];
  for (let i = 0; i < extras.length; i++) {
    const extra = extras[i];

    // Check if we need a new page
    if (yPosition > 270) {
      doc.addPage();
      yPosition = 30; // Reset Y position for the new page
    }
    doc.setFontSize(14);

    // Image on the left
    if (extra.images.length > 0) {
      try {
        const base64Image = await imageUrlToBase64(extra.images[0]);
        doc.addImage(
          base64Image.base64Image,
          "JPEG",
          14,
          yPosition,
          imageWidth,
          imageHeight
        );
      } catch (err) {
        console.warn(`Afbeelding kon niet worden geladen: ${extra.name}`);
        doc.setFont("Roboto", "italic");
        doc.text(
          "Afbeelding niet beschikbaar",
          14,
          yPosition + imageHeight / 2
        );
      }
    } else {
      doc.setFont("Roboto", "italic");
      doc.text("Afbeelding niet beschikbaar", 14, yPosition + imageHeight / 2);
    }

    // Title and description on the right
    const textXPosition = 14 + imageWidth + 10; // Space after the image
    doc.setFont("Roboto", "bold");
    doc.setFontSize(14);
    doc.text(extra.name || "Onbekend product", textXPosition, yPosition + 5);

    doc.setFont("Roboto", "normal");
    doc.setFontSize(12);
    const description = extra.description
      ? htmlToText(extra.description, {
          wordwrap: 0,
          preserveNewlines: true,
        })
      : "Geen beschrijving beschikbaar.";
    const descriptionLines = doc.splitTextToSize(description, 120); // Wrap description text to fit
    doc.text(descriptionLines, textXPosition, yPosition + 15);

    // Adjust yPosition for the next product
    yPosition +=
      Math.max(imageHeight, descriptionLines.length * lineSpacing) + 20;

    // Draw a border below the product (skip the last one)
    if (i < extras.length - 1) {
      doc.setDrawColor(200); // Light gray
      doc.setLineWidth(0.5);
      doc.line(14, yPosition, 200, yPosition); // Horizontal line from x=14 to x=200
      yPosition += 10; // Add spacing after the border
    }
  }
};

const Offerte1 = async (offerte) => {
  // console.log(offerte);
  const createdAt = offerte.createdAt?.seconds
    ? offerte.createdAt.seconds * 1000
    : offerte.sentAt;
  let sendAt = null;

  if (offerte.sentAt) {
    sendAt = offerte.sentAt?.seconds
      ? offerte.sentAt?.seconds * 1000
      : offerte.sentAt;
  }

  const createdDate = new Date(createdAt).toLocaleDateString("nl-NL");
  const sentDate = sendAt ? new Date(sendAt).toLocaleDateString("nl-NL") : null;

  const doc = new jsPDF();

  doc.setFont("Roboto", "normal");
  if (backgroundImage) {
    doc.addImage(backgroundImage, "JPEG", 0, 0, 210, 297); // A4 size in mm
  }

  doc.setFontSize(28);

  doc.setFont("Roboto", "bold");

  doc.text("Uw persoonlijke voorstel", 14, 60); // Adjust Y-position for spacing from the top

  // Subtitle Section
  doc.setFontSize(18); // Smaller font size for the subtitle
  doc.setFont("Roboto", "normal");
  // Normal font for the subtitle
  doc.text(offerte.selectedProduct.naam || "N/A", 14, 70); // Slightly below the title

  doc.setFontSize(14);
  const lineSpacing = 6; // Adjust line spacing here (smaller value = less spacing)

  // Left Column (Client Details)
  doc.setFont("Roboto", "bold");
  doc.text("Opgesteld voor:", 14, 90); // Header in bold

  doc.setFont("Roboto", "normal");
  doc.text(
    `${offerte.customerDetails?.firstName} ${offerte.customerDetails?.lastName}` ||
      "N/A",
    14,
    90 + lineSpacing
  );
  doc.text(
    `${offerte.customerDetails?.straat || "N/A"} ${
      offerte.customerDetails?.huisnummer || ""
    }`,
    14,
    90 + lineSpacing * 2
  );
  doc.text(
    `${offerte.customerDetails?.postcode || ""} ${
      offerte.customerDetails?.plaats || ""
    }`,
    14,
    90 + lineSpacing * 3
  );
  doc.text(offerte.customerDetails?.email || "N/A", 14, 90 + lineSpacing * 4);

  // Right Column (Company Details)
  const rightColumnX = 105; // Adjust for second column starting position
  doc.setFont("Roboto", "bold");
  doc.text("Gemaakt door:", rightColumnX, 90); // Header in bold

  doc.setFont("Roboto", "normal");
  doc.text("Thuisbatterij Experts", rightColumnX, 90 + lineSpacing);
  doc.text(
    `Datum: ${sentDate || createdDate}`,
    rightColumnX,
    90 + lineSpacing * 2
  );
  doc.text(
    `Offertenr.: ${offerte.offerteNr || "N/A"}`,
    rightColumnX,
    90 + lineSpacing * 3
  );

  doc.addPage();

  // Logo in the top right corner
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20); // Adjust positioning and size as needed

  // Add the text content
  doc.setFontSize(14);
  doc.setFont("Roboto", "normal");

  // Multiline text with word wrap
  let textContent = `
Beste ${offerte.customerDetails?.firstName} ${offerte.customerDetails?.lastName},

Naar aanleiding van uw aanvraag heb ik het genoegen u een persoonlijk aanbod te doen voor een thuisbatterijsysteem. Het voorgestelde systeem, inclusief de ${offerte.selectedProduct.naam}, is zorgvuldig samengesteld om perfect aan uw behoeften te voldoen en biedt een optimale combinatie van energieopslag, onafhankelijkheid en duurzaamheid.

Met dit systeem maakt u een bewuste keuze voor meer controle over uw energieverbruik, lagere kosten en een positieve impact op het milieu. In de bijlage vindt u alle details over het systeem, de installatie en de voordelen die u kunt verwachten.

Wij staan uiteraard klaar om eventuele vragen te beantwoorden en u verder te begeleiden in dit traject.`;

  if (offerte.inleiding) textContent = offerte.inleiding;
  doc.text(textContent.trim(), 14, 40, { maxWidth: 180 }); // Adjust positioning and text wrapping

  // Footer
  const footerText = `
www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl
`;
  doc.setFontSize(12);
  doc.setFont("Roboto", "normal");
  doc.text(footerText, 105, 280, { align: "center" }); // Footer centered horizontally

  // Add a new page

  const greenColor = "#18ba6a"; // Extracted green from the logo
  const normalFontSize = 12;
  const headerFontSize = 21;
  const subHeaderFontSize = 14;

  // Add a new page
  doc.addPage();

  // Logo in the top-right corner
  const logoX = 160; // Adjust X position for the logo
  const logoY = 15; // Adjust Y position for the logo
  const logoWidth = 30;
  const logoHeight = 15;
  doc.addImage(logoUrl, "JPEG", logoX, logoY, logoWidth, logoHeight);

  // Title
  doc.setFont("Roboto", "bold");
  doc.setFontSize(headerFontSize);

  doc.text("Over uw aanvraag", 14, 40);
  doc.setTextColor(greenColor);

  // Row 1, Column 1
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.text("Thuisbatterij", 14, 55);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Een thuisbatterij zoals de ${offerte.selectedProduct.naam} is meer dan alleen een opslagruimte voor energie; het is een sleutel naar energiebeheer. Door de opgewekte zonneenergie op te slaan, kunt u deze gebruiken wanneer u die het meest nodig heeft. Dit  betekent minder afhankelijkheid van het energienet en een optimale benutting van uw zonnepanelen. Met een ${offerte.selectedProduct.naam} kunt u rekenen op voldoende opslag om uw huishouden efficiënt van energie te voorzien, zelfs tijdens piekuren of stroomuitval.`,
    14,
    60,
    { maxWidth: 90 }
  );

  // Row 1, Column 2
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Onafhankelijkheid", 110, 55);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Met een thuisbatterij bent u minder afhankelijk van energieleveranciers en schommelende energieprijzen. De ${offerte.selectedProduct.naam} biedt u de mogelijkheid om uw eigen opgewekte  energie slim te gebruiken, wanneer dat het meest voordelig is. Dit geeft u de vrijheid en zekerheid om uw huishouden duurzamer en zelfstandiger te maken, zelfs bij veranderingen in het energielandschap.`,
    110,
    60,
    { maxWidth: 90 }
  );

  // Row 2, Column 1
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Uw duurzame verdienmodel", 14, 135);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `De investering in een thuisbatterij is een stap richting een duurzaam verdienmodel. U bespaart aanzienlijk op energiekosten door meer eigen zonne-energie te gebruiken in plaats van deze terug te leveren aan het net tegen lage tarieven. Met de ${offerte.selectedProduct.naam} profiteert u van een efficiënt systeem dat niet alleen bijdraagt aan kostenbesparing, maar ook de waarde van uw woning verhoogt.`,
    14,
    140,
    { maxWidth: 90 }
  );

  // Row 2, Column 2
  doc.setFont("Roboto", "bold");
  doc.setFontSize(subHeaderFontSize);
  doc.setTextColor(greenColor);
  doc.text("Uw rendement", 110, 135);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Het rendement van een thuisbatterij is indrukwekkend. Dankzij de ${offerte.selectedProduct.naam} kunt u uw eigen zonne-energie optimaal benutten, wat resulteert in lagere energiekosten en een korte terugverdientijd. Bovendien biedt dit systeem een oplossing voor stijgende netwerkkosten en dalende teruglevertarieven, waardoor u jaar na jaar blijft besparen. Zo haalt u het maximale uit uw duurzame investering.`,
    110,
    140,
    { maxWidth: 90 }
  );

  // Weetje Box
  const weetjeBoxX = 14;
  const weetjeBoxY = 200;
  const weetjeBoxWidth = 182;
  const weetjeBoxHeight = 26;
  doc.setFillColor("#fcf6dc");
  doc.rect(weetjeBoxX, weetjeBoxY, weetjeBoxWidth, weetjeBoxHeight, "F");

  // Draw "Weetje:" in bold
  doc.setFont("Roboto", "bold");
  doc.setFontSize(normalFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text("Weetje:", weetjeBoxX + 4, weetjeBoxY + 7);

  // Draw the rest of the text in normal font
  doc.setFont("Roboto", "normal");
  doc.text(
    `Onderzoek toont aan dat huishoudens met een thuisbatterij zoals de 
${offerte.selectedProduct.naam} gemiddeld 25% minder CO2 uitstoten. 
Door te kiezen voor energieopslag draagt u direct bij aan een duurzamer 
milieu en versterkt u uw impact op een groenere toekomst.`,
    weetjeBoxX + 20, // Adjust X to add spacing after "Weetje:"
    weetjeBoxY + 7
  );

  // Footer
  const footerY = 287; // Page height - 10 mm
  doc.setFont("Roboto", "normal");
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text(
    `
www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl
`,
    105,
    footerY,
    { align: "center" }
  );

  doc.addPage();

  // Add Logo in the top-right corner
  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20);

  // Title Section
  doc.setFont("Roboto", "bold");
  doc.setFontSize(22);
  doc.text("De Producten", 14, 40);

  // Subtitle
  doc.setFont("Roboto", "normal");
  doc.setFontSize(14);
  doc.text("Overzicht van de producten in uw offerte", 14, 50);

  // First Two Columns (Battery Modules and BMU)
  const columnWidth = 90;
  const lineSpacingg = 6;

  // Left Column
  let yPosition = 60;
  doc.setFont("Roboto", "bold");
  doc.setFontSize(14);
  doc.text("Batterij Modules", 14, yPosition);

  doc.setFont("Roboto", "normal");
  doc.setFontSize(14);
  doc.text(
    "De kern van het systeem, bestaande uit lithiumijzerfosfaat (LiFePO4) cellen, bekend om hun veiligheid en lange levensduur.",
    14,
    yPosition + lineSpacingg,
    { maxWidth: columnWidth }
  );

  doc.setFont("Roboto", "bold");
  doc.text("Battery Management Unit (BMU)", 14, yPosition + 35);

  doc.setFont("Roboto", "normal");
  doc.text(
    "Deze eenheid beheert en bewaakt de prestaties van de batterijmodules, zorgt voor een optimale werking en verlengt de levensduur van het systeem.",
    14,
    yPosition + 41,
    { maxWidth: columnWidth }
  );

  const imageX = 80;
  const imageY = 60;

  let base64Image = null;
  // Fixen in toekomst
  try {
    base64Image = await imageUrlToBase64(offerte.selectedProduct?.images[0]);
  } catch (err) {
    console.log(err);
  }
  //   console.log(base64Image);
  if (base64Image) {
    doc.addImage(base64Image.base64Image, "JPEG", 120, 40, 75, 75); // Adjust dimensions and position
  } else {
    doc.setFont("Roboto", "italic");
    doc.text("Afbeelding niet beschikbaar", imageX + 10, imageY + 30); // Placeholder text if image is missing
  }

  // Additional Descriptions Below
  yPosition = yPosition + 80; // Adjust for spacing after columns

  doc.setFont("Roboto", "bold");
  doc.text("Communicatiemodule", 14, yPosition);

  doc.setFont("Roboto", "normal");
  doc.text(
    "Voorzien van een ingebouwde Wi-Fi-module, waardoor u via een app de status van uw systeem in real-time kunt monitoren en updates op afstand kunt ontvangen.",
    14,
    yPosition + lineSpacingg,
    { maxWidth: 182 } // Full-width below columns
  );

  yPosition = yPosition + 20; // Adjust for spacing
  doc.setFont("Roboto", "bold");
  doc.text("Installatieaccessoires", 14, yPosition);

  doc.setFont("Roboto", "normal");
  doc.text(
    "Alle benodigde kabels, bevestigingsmaterialen en handleidingen voor een veilige en efficiënte installatie van het systeem. Afhankelijk van uw specifieke behoeften en de configuratie van het systeem, kunnen er extra componenten of accessoires worden toegevoegd om de functionaliteit en prestaties te optimaliseren.",
    14,
    yPosition + lineSpacingg,
    { maxWidth: 182 }
  );

  // Footer
  const footerYY = 287; // Page height - 10 mm
  doc.setFont("Roboto", "normal");
  doc.setFontSize(10);
  doc.setTextColor(0, 0, 0);
  doc.text(
    "www.thuisbatterijexperts.nl    +31 (085) 250 2232    info@thuisbatterijexperts.nl",
    105,
    footerYY,
    { align: "center" }
  );
  console.log(offerte);
  if (offerte.selectedExtras.length > 0) {
    await addExtraProductsSection(doc, offerte);
  }

  // Add a new page for "Het Aanbod"
  doc.addPage();

  doc.addImage(logoUrl, "JPEG", 160, 10, 40, 20);

  // Header
  doc.setFont("Roboto", "bold");
  doc.setFontSize(20);
  const top = 40;

  doc.text("Het aanbod", 14, top);
  doc.setFontSize(10);
  doc.setFont("Roboto", "normal");
  doc.text("Thuisbatterij Experts", 140, top + 15);
  doc.text("Parnassusweg 101 103", 140, top + 20);
  doc.text("1077 DE Amsterdam", 140, top + 25);
  doc.text("KvK: 80640842", 140, top + 30);
  doc.text("Btw.nr.: NL003470826B34", 140, top + 35);

  // Client Details
  doc.setFont("Roboto", "normal");
  doc.text(
    `${offerte.customerDetails?.firstName} ${offerte.customerDetails?.lastName}` ||
      "N/A",
    14,
    top + 15
  );
  doc.text(
    `${offerte.customerDetails?.straat || "N/A"} ${
      offerte.customerDetails?.huisnummer || ""
    }`,
    14,
    top + 20
  );
  doc.text(
    `${offerte.customerDetails?.postcode || ""} ${
      offerte.customerDetails?.plaats || ""
    }`,
    14,
    top + 25
  );
  doc.text(offerte.customerDetails?.email || "N/A", 14, top + 30);

  doc.text(`Offertedatum: ${sentDate || createdDate}`, 14, top + 40);
  doc.text(`Offertenr.: ${offerte.offerteNr || "N/A"}`, 14, top + 45);

  // Table Header
  doc.setFontSize(10);
  doc.setFillColor(24, 186, 106); // Green header background
  doc.rect(14, top + 50, 182, 10, "F"); // Full-width header

  doc.setTextColor(255, 255, 255); // White text
  doc.text("Naam", 16, top + 56);
  doc.text("Aantal", 100, top + 56);
  doc.text("Subtotaal", 165, top + 56);

  // Table Rows
  doc.setTextColor(0, 0, 0); // Reset to black text
  let yPos = top + 65;

  // Add selected product
  if (offerte.selectedProduct) {
    const productName = offerte.selectedProduct.naam || "Onbekend";
    const productDescription = htmlToText(
      offerte.selectedProduct.beschrijving || "Geen beschrijving",
      {
        wordwrap: 90, // Adjust word wrapping for the PDF layout
      }
    );

    // Add product name
    doc.text(productName, 16, yPos);

    // Add product description (word-wrapped for PDF)
    const descriptionLines = doc.splitTextToSize(productDescription, 150); // Adjust width for description
    descriptionLines.forEach((line, index) => {
      doc.text(line, 20, yPos + lineSpacing * (index + 1));
    });

    // Add quantity and price
    doc.text("1", 100, yPos, { align: "right" });
    if (offerte.selectedProduct.solar) {
      doc.text(
        `€${parseFloat(
          offerte.selectedProduct.prijs *
            offerte.selectedProduct.solarPanelCount
        ).toFixed(2)}`,
        182,
        yPos,
        { align: "right" }
      );
    } else {
      doc.text(
        `€${parseFloat(offerte.selectedProduct.prijs).toFixed(2)}`,
        182,
        yPos,
        { align: "right" }
      );
    }

    // Adjust `yPos` for the added lines
    yPos += lineSpacing * (descriptionLines.length + 1.5);
  }

  // Add selected extras
  offerte.selectedExtras.forEach((extra) => {
    if (extra.type === "quantity") {
      doc.text(extra.name || "Onbekend", 16, yPos);
      doc.text(`${extra.quantity || 1}`, 100, yPos, { align: "right" });
      doc.text(
        `€${(extra.quantity * parseFloat(extra.price)).toFixed(2)}`,
        182,
        yPos,
        { align: "right" }
      );
      yPos += lineSpacing;
    } else {
      doc.text(extra.name || "Onbekend", 16, yPos);
      doc.text(`1`, 100, yPos, { align: "right" });
      doc.text(`€${(1 * parseFloat(extra.price)).toFixed(2)}`, 182, yPos, {
        align: "right",
      });
      yPos += lineSpacing;
    }
  });
  doc.rect(14, yPos - 1.5, 182, 0);
  // Total Price Details
  yPos += 5;
  doc.setFont("Roboto", "bold");
  doc.text("Subtotaal excl. btw", 160, yPos, { align: "right" });
  doc.text(
    `€${parseFloat(offerte.priceDetails.basePrice).toFixed(2)}`,
    182,
    yPos,
    { align: "right" }
  );
  if (offerte.appliedDiscounts && offerte.appliedDiscounts.length > 0) {
    yPos += lineSpacing;
    doc.text("Korting", 160, yPos, { align: "right" });
    doc.text(
      `-€${parseFloat(
        offerte.priceDetails.basePrice - offerte.priceDetails.discountedPrice
      ).toFixed(2)}`,
      182,
      yPos,
      { align: "right" }
    );

    yPos += lineSpacing;
    doc.text("Totaal excl. btw", 160, yPos, { align: "right" });
    doc.text(
      `€${parseFloat(offerte.priceDetails.discountedPrice).toFixed(2)}`,
      182,
      yPos,
      { align: "right" }
    );
  }

  doc.rect(120, yPos + 1.6, 80, 0);
  yPos += lineSpacing;
  doc.text("Totaal btw 21%", 160, yPos, { align: "right" });
  doc.text(`€${parseFloat(offerte.priceDetails.VAT).toFixed(2)}`, 182, yPos, {
    align: "right",
  });

  yPos += lineSpacing;
  doc.setFontSize(12);
  doc.text("Totaal incl. btw", 160, yPos, { align: "right" });
  doc.text(
    `€${parseFloat(offerte.priceDetails.totalIncludingVAT).toFixed(2)}`,
    182,
    yPos,
    { align: "right" }
  );

  if (offerte.selectedFinancingOption) {
    doc.text("Betalingscondities: ", 14, yPos);
    doc.setFont("Roboto", "normal");
    doc.text(offerte.selectedFinancingOption.name, 14, yPos + 5);
    doc.setFontSize(11);

    doc.setFont("Roboto", "italic");
    doc.text(offerte.selectedFinancingOption.paymentCondition, 14, yPos + 10);
  }
  // Add Signatures Section
  yPos += 20; // Add spacing after the table
  doc.setFontSize(12);
  doc.setFont("Roboto", "bold");
  doc.text("Geaccepteerd door:", 14, yPos);

  // Add "Thuisbatterij Experts" signature section
  yPos += 10; // Add spacing for signatures
  doc.setFont("Roboto", "normal");
  doc.text("Thuisbatterij Experts", 14, yPos); // Name above line
  doc.addImage(Signature, "JPEG", 14, yPos + 3, 60, 20);
  doc.line(14, yPos + 18, 70, yPos + 18); // Signature line for "Thuisbatterij Experts"

  // Add customer signature section
  doc.text(
    `${offerte.customerDetails?.firstName} ${offerte.customerDetails?.lastName}` ||
      "Klant",
    110,
    yPos
  ); // Customer name above line
  if (offerte.status === "accepted" && offerte.signature) {
    doc.addImage(offerte.signature, "JPEG", 120, yPos + 3, 30, 15);
  }
  doc.line(110, yPos + 18, 182, yPos + 18); // Signature line for customer

  // Add date below the signatures
  yPos += 25; // Add more spacing for date

  const createdLine = `Datum: ${sentDate || createdDate}`;
  doc.text(createdLine, 14, yPos); // Date for "Thuisbatterij Experts"

  if (offerte.status === "accepted") {
    const acceptanceDate = new Date(
      offerte.signedAt.seconds * 1000
    ).toLocaleDateString("nl-NL");
    const acceptedLine = `Datum: ${acceptanceDate}`;
    doc.text(acceptedLine, 110, yPos); // Date for the customer
  }

  // Footer
  doc.setFontSize(10);
  doc.setFont("Roboto", "normal");
  doc.text(
    "www.thuisbatterijexperts.nl        +31 (085) 250 2232        info@thuisbatterijexperts.nl",
    105,
    287,
    { align: "center" }
  );

  const appendDatasheets = async (jsPdfDoc) => {
    const datasheets = [];

    // Collect datasheet URLs
    if (offerte.selectedProduct?.datasheets.length > 0) {
      offerte.selectedProduct.datasheets.map((a) => datasheets.push(a));
      // datasheets.push(offerte.selectedProduct.datasheets);
    }

    if (offerte.selectedExtras?.length > 0) {
      offerte.selectedExtras.forEach((extra) => {
        if (extra.pdfs.length > 0) {
          extra.pdfs.map((a) => datasheets.push(a));
          // datasheets.push(extra.pdfs);
        }
      });
    }

    if (datasheets.length === 0) {
      console.warn("No datasheets to append.");
      return jsPdfDoc;
    }
    // return jsPdfDoc;
    try {
      // Convert jsPDF to pdf-lib document
      const jsPdfBytes = jsPdfDoc.output("arraybuffer");
      const mainPdf = await PDFDocument.load(jsPdfBytes);
      console.log(datasheets);
      // Process each datasheet URL
      for (const url of datasheets) {
        const response = await fetch(url);
        console.log(url);
        const arrayBuffer = await response.arrayBuffer();
        const externalPdf = await PDFDocument.load(arrayBuffer);

        // Copy pages from the datasheet PDF to the main PDF
        const copiedPages = await mainPdf.copyPages(
          externalPdf,
          externalPdf.getPageIndices()
        );

        copiedPages.forEach((page) => mainPdf.addPage(page));
      }

      // Save the final PDF with appended datasheets
      const mergedPdfBytes = await mainPdf.save();

      return mergedPdfBytes; // Return the final PDF as bytes
    } catch (error) {
      console.error("Failed to append datasheets:", error);
      throw new Error("Unable to append datasheets.");
    }
  };
  // Call the function to append datasheets
  const newDoc = await appendDatasheets(doc);

  // Generate Blob URL
  const pdfBlob = new Blob([newDoc], {
    type: "application/pdf",
  });
  return pdfBlob;
};

export default Offerte1;
