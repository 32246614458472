import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
  query,
  where,
  limit,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import Offerte1 from "../templates/offertes/Offerte1"; // Adjust the path
import axios from "axios";
import {
  FaEye,
  FaEdit,
  FaPaperPlane,
  FaTrashAlt,
  FaInfoCircle,
} from "react-icons/fa";
import moment from "moment";

const getIcon = (message) => {
  if (message.includes("gestuurd")) {
    // Log related to sending
    return <FaPaperPlane style={{ color: "#23d17a" }} />;
  } else if (message.includes("bekeken")) {
    // Log related to viewing
    return <FaEye style={{ color: "#007bff" }} />;
  } else {
    // Default log
    return <FaInfoCircle style={{ color: "#ffc107" }} />;
  }
};

const addLog = async ({ offerteId, userId, message, extraDetails = null }) => {
  try {
    const logData = {
      offerteId,
      userId: userId || "client", // Default to "client" if no user ID is provided
      message,
      createdAt: serverTimestamp(),
      // ipAddress: null, // Optionally populate this
      // extraDetails,
    };

    await addDoc(collection(db, "logs"), logData);
    // console.log("Log added successfully");
  } catch (error) {
    console.error("Error adding log:", error);
  }
};

const checkRecentLog = async (offerteId) => {
  // console.log(offerteId);
  const logsRef = collection(db, "logs");
  const q = query(
    logsRef,
    where("offerteId", "==", offerteId),
    where("message", "==", "Klant heeft offerte bekeken"),
    orderBy("createdAt", "desc"),
    limit(1)
  );

  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const recentLog = querySnapshot.docs[0].data();
    const oneHourAgo = new Date(new Date() - 60 * 60 * 1000);
    return new Date(recentLog.createdAt.seconds * 1000) > oneHourAgo;
  }

  return false;
};

const generatePasscode = (length = 8) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let passcode = "";
  for (let i = 0; i < length; i++) {
    passcode += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return passcode;
};

const verifyPasscode = async (offer, inputPasscode) => {
  if (offer) {
    const data = offer;
    return data.passcode === inputPasscode; // Validate passcode
  } else {
    throw new Error("Data not found");
  }
};

const sendQuoteEmail = async (offerte) => {
  try {
    const quoteUrl = `${process.env.REACT_APP_WEB_URL}/public/offerte/${offerte.id}/${offerte.passcode}`;

    const email = {
      to: offerte.customerDetails.email,
      subject: "Uw persoonlijke offerte van Thuisbatterij Experts",
      placeholders: {
        name: `${offerte.customerDetails.firstName} ${offerte.customerDetails.lastName}`,
        quote_url: quoteUrl,
        inleiding: offerte.inleiding || null,
      },
      templateName: "quote",
      customArgs: {
        clientId: offerte.id, // Replace with your client's ID
        offerteNr: offerte.offerteNr, // Replace with your record's unique ID
      },
    };

    const emailResponse = await sendEmailWithAttachment(email, offerte);
    return emailResponse;
  } catch (error) {
    console.error("Failed to send email:", error);
  }
};

const createFollowUpTask = async (offerte) => {
  try {
    const followUpDate = new Date();
    followUpDate.setDate(followUpDate.getDate() + 3); // Drie dagen later

    const agent = offerte.agent;

    // Fetch all tasks for the agent on the follow-up day
    const startOfDay = new Date(followUpDate);
    startOfDay.setHours(0, 0, 0, 0); // Start of the day
    const endOfDay = new Date(followUpDate);
    endOfDay.setHours(23, 59, 59, 999); // End of the day

    const tasksRef = collection(db, "tasks");
    const q = query(
      tasksRef,
      where("agent", "==", agent),
      where("startTime", ">=", startOfDay),
      where("startTime", "<=", endOfDay)
    );

    const tasksSnapshot = await getDocs(q);
    const tasks = tasksSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        startTime: data.startTime.toDate(), // Convert Firebase Timestamp to Date
        endTime: data.endTime.toDate(), // Convert Firebase Timestamp to Date
      };
    });
    // Sort tasks by startTime for easier time slot calculation
    const sortedTasks = tasks.sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );

    // Define working hours (e.g., 10:00 AM to 5:00 PM)
    const workStart = new Date(startOfDay);
    workStart.setHours(10, 0, 0, 0);
    const workEnd = new Date(startOfDay);
    workEnd.setHours(17, 0, 0, 0);

    // Find a 15-minute slot with a buffer
    let availableSlot = null;
    let currentStart = workStart;

    for (const task of sortedTasks) {
      const taskStart = new Date(task.startTime);
      const taskEndWithBuffer = new Date(
        new Date(task.endTime).getTime() + 15 * 60 * 1000
      );

      // Check if there is a slot before this task
      if (taskStart - currentStart >= 30 * 60 * 1000) {
        // 15 minutes for the task + 15 minutes buffer
        availableSlot = new Date(currentStart);
        break;
      }

      // Move the current start to the end of this task plus buffer
      currentStart = new Date(Math.max(currentStart, taskEndWithBuffer));
    }

    // Check after the last task with a buffer
    if (!availableSlot && workEnd - currentStart >= 30 * 60 * 1000) {
      availableSlot = new Date(currentStart);
    }

    if (!availableSlot) {
      // console.log("Geen beschikbare tijdslot gevonden.");
      return;
    }

    // Create the follow-up task
    const task = {
      type: "bel afspraak",
      title: `#${offerte.offerteNr}: opvolgen offerte`,
      agent: offerte.agent,
      startTime: availableSlot,
      endTime: new Date(availableSlot.getTime() + 15 * 60 * 1000), // 15 minuten later
      lead: offerte.id,
      description: `Volg offerte op met ${offerte.customerDetails.firstName} ${offerte.customerDetails.lastName}.`,
      comments: "Automatisch gegenereerd voor opvolging",
      createdAt: new Date(),
    };
    // console.log(task);
    const taskRef = collection(db, "tasks");
    await addDoc(taskRef, task);
    await addLog({
      offerteId: offerte.offerteNr,
      userId: offerte.agent,
      message: `Automatische opvolgtaak aangemaakt voor ${moment(
        availableSlot
      ).format("LLL")} `,
    });
  } catch (error) {
    console.error("Error creating follow-up task:", error);
  }
};

const sendEmailWithAttachment = async (emailData, offerteData) => {
  try {
    const attachment = await generateOfferte(offerteData);
    const email = {
      ...emailData,
      // attachment,
      // attachmentName: `${offerteData.customerDetails.firstName} ${offerteData.customerDetails.lastName} - Offerte Thuisbatterij Experts.pdf`,
    };

    return await sendEmail(email);
    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email:", error.message);
  }
};

const sendEmail = async (data) => {
  // console.log(data);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/send-email`,
      data
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error sending email:",
      error.response?.data || error.message
    );
    return false;
    throw new Error("Failed to send email.");
  }
};

const generateOfferte = async (offerteData) => {
  // console.log(offerteData);
  const pdfBlob = await Offerte1(offerteData);
  const pdfBase64 = await blobToBase64(pdfBlob);
  return pdfBase64;
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]); // Remove the prefix
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const sendOfferteToClient = async (offerte) => {
  try {
    await sendQuoteEmail(offerte);
    // console.log("Email sent successfully!");
  } catch (error) {
    console.log(`Error: ${error.message}`);
  }
};

const getNextOfferteNumber = async () => {
  const offerCounterRef = doc(db, "offerCounters", "offerCounter");

  try {
    // Fetch the current document
    const offerCounterSnap = await getDoc(offerCounterRef);

    if (!offerCounterSnap.exists()) {
      throw new Error("Offer counter document does not exist.");
    }

    // Get the current last offer number
    const currentNumber = offerCounterSnap.data().lastOfferNumber;

    // Increment the offer number
    const nextNumber = currentNumber + 1;

    // Update the database with the new number
    await updateDoc(offerCounterRef, { lastOfferNumber: nextNumber });

    return nextNumber;
  } catch (error) {
    console.error("Error fetching or updating offerte number: ", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

const toastHelper = {
  success: (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error: (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  info: (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};

const convertToDate = (timestamp) => {
  // console.log(timestamp);
  if (timestamp && timestamp.seconds) {
    return new Date(timestamp.seconds * 1000);
  }
  if (timestamp instanceof Date) {
    return timestamp; // Already a Date object
  }
  console.warn("Unexpected format for timestamp:", timestamp);
  return null; // Handle unexpected formats gracefully
};

const getStatusName = (status) => {
  const statusMap = {
    delivered: "Afgeleverd",
    Verzonden: "Verzonden",
    pending: "In afwachting",
    accepted: "Geaccepteerd",
    declined: "Geweigerd",
    bounce: "Niet afgeleverd",
    concept: "Concept",
    cancelled: "Geannuleerd",
    Geannuleerd: "Geannuleerd",
    afgewezen: "Geannuleerd",
  };

  return statusMap[status] || "Onbekend";
};

const formatDutchPrice = (amount) => {
  if (isNaN(amount)) return "€ 0,00";

  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  }).format(amount);
};

export {
  generatePasscode,
  verifyPasscode,
  sendEmail,
  sendEmailWithAttachment,
  sendQuoteEmail,
  sendOfferteToClient,
  getNextOfferteNumber,
  toastHelper,
  createFollowUpTask,
  addLog,
  checkRecentLog,
  getIcon,
  convertToDate,
  getStatusName,
  formatDutchPrice,
};
