import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebaseConfig";
import { useUser } from "../context/UserContext";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import "./LeadsPage.css";
import moment from "moment/moment";
import TaskModal from "../components/AddTaskModal";
import { convertToDate, toastHelper } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import _ from "lodash";

const LeadsPage = () => {
  const { user } = useUser();
  const [leads, setLeads] = useState([]);
  const [agents, setAgents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [selectedTaskLead, setSelectedTaskLead] = useState(null); // Track the lead for TaskModal
  const [currentPageLeads, setCurrentPageLeads] = useState(0);
  const [currentPageOthers, setCurrentPageOthers] = useState(0);
  const [currentPageAppointments, setCurrentPageAppointments] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isReclaimNoteModalOpen, setReclaimNoteModalOpen] = useState(false); // Track if the notitie modal is open
  const [reclaimReason, setReclaimReason] = useState(""); // Store the reclaim reason

  const itemsPerPage = 5;

  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const navigate = useNavigate();

  const [notes, setNotes] = useState([]); // To store fetched notes
  const [newNote, setNewNote] = useState({ title: "", description: "" });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleCategoryFilter = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handlePageClickLeads = (selected) => {
    setCurrentPageLeads(selected.selected);
  };

  const handlePageClickOthers = (selected) => {
    setCurrentPageOthers(selected.selected);
  };

  const handlePageClickAppointments = (selected) => {
    setCurrentPageAppointments(selected.selected);
  };
  const filteredLeads = leads.filter((lead) => {
    const { customerDetails } = lead;

    // Check search query
    const matchesSearch =
      customerDetails.firstName.toLowerCase().includes(searchQuery) ||
      customerDetails.lastName.toLowerCase().includes(searchQuery) ||
      customerDetails.email.toLowerCase().includes(searchQuery) ||
      (customerDetails.straat || "").toLowerCase().includes(searchQuery) ||
      (customerDetails.plaats || "").toLowerCase().includes(searchQuery);

    // Check category filter (if applicable)
    const matchesCategory =
      !selectedCategory || customerDetails.category === selectedCategory;

    return matchesSearch && matchesCategory;
  });
  const [newLead, setNewLead] = useState({
    agent: null,
    archived: false,
    selectedProduct: null,
    customerDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      straat: "",
      plaats: "",
      provincie: "",
      woonfunctie: "",
      lat: "",
      lng: "",
      oppervlakte: "",
      bouwjaar: "",
      huisnummer: "",
      toevoeging: "",
      postcode: "",
    },
    selectedExtras: [],
    notes: [],
    appliedDiscounts: [],
    finalPrice: 0,
    passcode: null,
    priceDetails: {
      VAT: 0,
      basePrice: 0,
      discountedPrice: 0,
      totalIncludingVAT: 0,
    },
    status: "",
    signature: null,
    signedAt: null,
    sentAt: null,
    createdAt: null,
    updatedAt: null,
  });

  useEffect(() => {
    const fetchLeadsAndAgents = async () => {
      try {
        await fetchLeads();

        const agentsSnap = await getDocs(collection(db, "users"));
        const fetchedAgents = agentsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAgents(fetchedAgents);
      } catch (error) {
        console.error("Error fetching leads or agents:", error);
      }
    };

    fetchLeadsAndAgents();
  }, []);

  const fetchLeads = async () => {
    try {
      let leadsQuery;

      if (user.role === "admin") {
        // Fetch all leads for admin
        leadsQuery = collection(db, "leads");
      } else {
        // Fetch only leads assigned to the current user
        leadsQuery = query(
          collection(db, "leads"),
          where("agent", "==", user.uid)
        );
      }

      const leadsSnap = await getDocs(leadsQuery);

      const fetchedLeads = leadsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const sortedFetchedLeads = fetchedLeads.sort((a, b) => {
        return new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate());
      });
      setLeads(sortedFetchedLeads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleOfferModal = () => setOfferModalOpen(!offerModalOpen);
  const toggleTaskModal = (lead = null) => {
    setTaskModalOpen(!taskModalOpen);
  };
  const saveTask = () => {
    setTaskModalOpen(false);
    setSelectedLead(null);
    toggleOfferModal();
  };
  const toggleNoteModal = () => setNoteModalOpen(!noteModalOpen);

  const handleScheduleAppointment = async (task) => {
    try {
      await addDoc(collection(db, "tasks"), {
        ...task,
        leadId: selectedLead.id,
      });
      toastHelper.success("Afspraak succesvol gepland.");
      toggleTaskModal();
    } catch (error) {
      console.error("Error scheduling appointment:", error);
      toastHelper.error(
        "Er is een fout opgetreden bij het plannen van de afspraak."
      );
    }
  };

  const handleAddressSearch = async () => {
    const { postcode, huisnummer, toevoeging } = newLead.customerDetails;
    if (!postcode || !huisnummer) return;

    try {
      const url = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${
        process.env.REACT_APP_PRO6BB
      }&nl_sixpp=${postcode}&streetnumber=${huisnummer}&extension=${
        toevoeging ? toevoeging : ""
      }`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        if (data && data.results && data.results.length > 0) {
          const result = data.results[0];
          setNewLead((prev) => ({
            ...prev,
            customerDetails: {
              ...prev.customerDetails,
              straat: result.street,
              plaats: result.city,
              provincie: result.province,
              woonfunctie: result.functions,
              lat: result.lat,
              lng: result.lng,
              oppervlakte: result.surface,
              bouwjaar: result.construction_year,
              huisnummer: result.streetnumber,
              toevoeging: result.extension,
              postcode: result.nl_sixpp,
            },
          }));
        }
      } else {
        alert("Geen adres gevonden voor deze postcode en huisnummer.");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      alert("Fout bij het ophalen van adresgegevens.");
    }
  };

  const handleAddLead = async () => {
    const {
      customerDetails: {
        firstName,
        lastName,
        email,
        phone,
        straat,
        huisnummer,
        postcode,
        plaats,
      },
    } = newLead;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !straat ||
      !huisnummer ||
      !postcode ||
      !plaats
    ) {
      alert("Vul alle velden in.");
      return;
    }
    const newObj = {
      ...newLead,
      createdAt: new Date(),
      updatedAt: new Date(),
      status: "lead",
      agent: user.uid,
      notes: notes,
    };
    // console.log(newObj);
    // return
    try {
      await addDoc(collection(db, "leads"), newObj);

      setNewLead({
        agent: user.uid,
        archived: false,
        selectedProduct: null,
        customerDetails: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          straat: "",
          plaats: "",
          provincie: "",
          woonfunctie: "",
          lat: "",
          lng: "",
          oppervlakte: "",
          bouwjaar: "",
          huisnummer: "",
          toevoeging: "",
          postcode: "",
        },
        selectedExtras: [],
        appliedDiscounts: [],
        finalPrice: 0,
        passcode: null,
        priceDetails: {
          VAT: 0,
          basePrice: 0,
          discountedPrice: 0,
          totalIncludingVAT: 0,
        },
        status: "",
        signature: null,
        signedAt: null,
        sentAt: null,
        createdAt: null,
        updatedAt: null,
        notes: [],
      });
      toggleModal();
      fetchLeads();
      toastHelper.success("Lead is toegevoegd!");
    } catch (error) {
      toastHelper.success("Er gaat iets fout. Probeer het later nog eens!");

      console.error("Error adding lead:", error);
    }
  };

  const assignAgentToLead = async (leadId, agentId) => {
    if (!agentId) return;

    try {
      const leadRef = doc(db, "leads", leadId);
      await updateDoc(leadRef, { agent: agentId });

      // Update state to reflect the change
      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === leadId ? { ...lead, agent: agentId } : lead
        )
      );

      alert("Agent succesvol toegewezen.");
    } catch (error) {
      console.error("Error assigning agent:", error);
      alert("Er is een fout opgetreden bij het toewijzen van de agent.");
    }
  };

  const handleCreateOffer = async () => {
    if (!selectedLead) return;
    selectedLead.status = "concept";
    try {
      navigate("/offerte", { state: { lead: selectedLead } });
    } catch (error) {
      console.error("Error creating offer:", error);
      alert("Fout bij het aanmaken van de offerte.");
    }
  };

  const openLeadInfo = (lead) => {
    console.log(lead);
    const date = convertToDate(lead.appointment);
    // console.log(date);
    lead.appointmentDate = date;
    setSelectedLead(lead);
    toggleOfferModal();
  };

  const handleAddNote = async () => {
    if (!newNote.title || !newNote.description) {
      toastHelper.error("Vul een titel en beschrijving in.");
      return;
    }

    try {
      const noteData = {
        ...newNote,
        createdAt: new Date(),
        userId: user?.uid,
      };

      // await addDoc(collection(db, `leads/${selectedLead.id}/notes`), noteData);

      setNotes([...notes, noteData]);
      setNewNote({ title: "", description: "" });
      toastHelper.success("Notitie toegevoegd!");
      toggleNoteModal();
    } catch (error) {
      console.error("Error adding note:", error);
      toastHelper.error("Fout bij het toevoegen van de notitie.");
    }
  };

  const handleDeleteNote = async (index) => {
    try {
      setNotes((prevNotes) => prevNotes.filter((note, i) => i !== index));
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const updateLeadStatus = async (leadId, status) => {
    if (status === "reclameren") {
      setSelectedLead(leads.find((lead) => lead.id === leadId));
      setReclaimReason(""); // Reset the note input
      setReclaimNoteModalOpen(true); // Open the notitie modal
      return;
    }

    // Update status directly for other actions
    try {
      const leadRef = doc(db, "leads", leadId);
      await updateDoc(leadRef, { status });
      toastHelper.success("Status succesvol bijgewerkt.");
      fetchLeads(); // Refresh leads after status update
    } catch (error) {
      console.error("Error updating lead status:", error);
      toastHelper.error(
        "Er is een fout opgetreden bij het bijwerken van de status."
      );
    }
  };
  const submitReclaimNote = async () => {
    if (!reclaimReason) {
      toastHelper.error("De reden voor reclameren is verplicht.");
      return;
    }

    try {
      const leadRef = doc(db, "leads", selectedLead?.id);
      await updateDoc(leadRef, {
        status: "reclameren",
        notes: [
          ...(selectedLead?.notes || []),
          {
            title: "Reclamatie",
            description: reclaimReason,
            createdAt: new Date(),
            userId: user.uid,
          },
        ],
      });
      toastHelper.success("Reclamatie succesvol toegevoegd.");
      setReclaimNoteModalOpen(false); // Close the modal
      fetchLeads(); // Refresh leads after the update
    } catch (error) {
      console.error("Error adding reclamation note:", error);
      toastHelper.error(
        "Er is een fout opgetreden bij het opslaan van de notitie."
      );
    }
  };

  // console.log(user);
  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h3>Leadsbeheer</h3>
        <Button color="primary" onClick={toggleModal}>
          Nieuwe Lead
        </Button>
      </div>
      <Row className="align-items-center mb-4">
        <Col md={6}>
          <Input
            type="text"
            placeholder="Zoek op naam, email of adres..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </Col>
        {/* <Col md={4}>
          <Input
            type="select"
            value={selectedCategory}
            onChange={handleCategoryFilter}
          >
            <option value="">Alle Categorieën</option>
            <option value="residential">Residentieel</option>
            <option value="commercial">Commercieel</option>
            <option value="industrial">Industrieel</option>
          </Input>
        </Col> */}
      </Row>
      <Row className="mt-4">
        {/* Nieuwe Leads */}
        <Col>
          <h4>Nieuwe leads</h4>
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Naam</th>
                    <th>Email</th>
                    <th>Adres</th>
                    <th>Status</th>
                    {user.role === "admin" && <th>Agent</th>}
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody className="scrollable-table">
                  {leads.filter((lead) => ["lead", "new"].includes(lead.status))
                    .length === 0 && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Geen nieuwe leads
                      </td>
                    </tr>
                  )}
                  {filteredLeads
                    .filter((lead) => ["lead", "new"].includes(lead.status))
                    .slice(
                      currentPageLeads * itemsPerPage,
                      (currentPageLeads + 1) * itemsPerPage
                    )
                    .map((lead) => (
                      <tr key={lead.id}>
                        <td>{moment(lead.createdAt.toDate()).format("lll")}</td>
                        <td>
                          {lead.customerDetails.firstName}{" "}
                          {lead.customerDetails.lastName}
                        </td>
                        <td>{lead.customerDetails.email}</td>
                        <td>
                          {lead.customerDetails.straat}{" "}
                          {lead.customerDetails.huisnummer}{" "}
                          {lead.customerDetails.toevoeging},{" "}
                          {lead.customerDetails.plaats}
                        </td>
                        <td>{lead.status}</td>
                        {user.role === "admin" && (
                          <td>
                            {lead.agent ? (
                              agents.find((agent) => agent.id === lead.agent)
                                ?.personalInfo?.firstName || "Onbekend"
                            ) : (
                              <Input
                                type="select"
                                value=""
                                onChange={(e) =>
                                  assignAgentToLead(lead.id, e.target.value)
                                }
                              >
                                <option value="">Selecteer een agent...</option>
                                {agents.map((agent) => (
                                  <option key={agent.id} value={agent.id}>
                                    {agent.personalInfo?.firstName ||
                                      "Onbekend"}
                                  </option>
                                ))}
                              </Input>
                            )}
                          </td>
                        )}
                        <td>
                          <Button
                            color="success"
                            size="sm"
                            onClick={() => openLeadInfo(lead)}
                          >
                            Verwerk
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"Vorige"}
                nextLabel={"Volgende"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  filteredLeads.filter((lead) =>
                    ["lead", "new"].includes(lead.status)
                  ).length / itemsPerPage
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickLeads}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </CardBody>
          </Card>
        </Col>

        {/* Leads met geplande afspraken */}
        <Col xs={12} className="mt-4">
          <h4>Geplande afspraken</h4>
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Naam</th>
                    <th>Adres</th>
                    <th>Datum</th>

                    <th>Soort afspraak</th>
                    <th>Status</th>
                    {user.role === "admin" && <th>Agent</th>}
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {leads.filter((lead) => lead.status === "appointment")
                    .length === 0 && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Geen geplande afspraken
                      </td>
                    </tr>
                  )}
                  {filteredLeads
                    .filter((lead) => lead.status === "appointment")
                    .slice(
                      currentPageAppointments * itemsPerPage,
                      (currentPageAppointments + 1) * itemsPerPage
                    )
                    .map((lead) => {
                      return (
                        <tr key={lead.id}>
                          <td>
                            {lead.customerDetails.firstName}{" "}
                            {lead.customerDetails.lastName}
                          </td>

                          <td>
                            {lead.customerDetails.straat}{" "}
                            {lead.customerDetails.huisnummer}{" "}
                            {lead.customerDetails.toevoeging},{" "}
                            {lead.customerDetails.plaats}
                          </td>
                          <td>
                            {moment(lead.appointment.toDate()).format("lll")}
                          </td>
                          <td>{lead?.appointmentType}</td>
                          <td>{lead.status}</td>
                          {user.role === "admin" && (
                            <td>
                              {agents.find((agent) => agent.id === lead.agent)
                                ?.personalInfo?.firstName || "Onbekend"}
                            </td>
                          )}
                          <td>
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => openLeadInfo(lead)}
                            >
                              Bekijk
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"Vorige"}
                nextLabel={"Volgende"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  filteredLeads.filter((lead) => lead.status === "appointment")
                    .length / itemsPerPage
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickAppointments}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} className="mt-4">
          <h4>Niet bereikte leads</h4>
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Naam</th>
                    <th>Email</th>
                    <th>Adres</th>
                    <th>Status</th>
                    {user.role === "admin" && <th>Agent</th>}
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody className="scrollable-table">
                  {filteredLeads.filter(
                    (lead) =>
                      !["lead", "new", "appointment"].includes(lead.status)
                  ).length === 0 && (
                    <tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>
                        Geen nieuwe leads
                      </td>
                    </tr>
                  )}
                  {filteredLeads
                    .filter(
                      (lead) =>
                        !["lead", "new", "appointment"].includes(lead.status)
                    )
                    .slice(
                      currentPageLeads * itemsPerPage,
                      (currentPageLeads + 1) * itemsPerPage
                    )
                    .map((lead) => (
                      <tr key={lead.id}>
                        <td>{moment(lead.createdAt.toDate()).format("lll")}</td>
                        <td>
                          {lead.customerDetails.firstName}{" "}
                          {lead.customerDetails.lastName}
                        </td>
                        <td>{lead.customerDetails.email}</td>
                        <td>
                          {lead.customerDetails.straat}{" "}
                          {lead.customerDetails.huisnummer}{" "}
                          {lead.customerDetails.toevoeging},{" "}
                          {lead.customerDetails.plaats}
                        </td>
                        <td>{lead.status}</td>
                        {user.role === "admin" && (
                          <td>
                            {lead.agent ? (
                              agents.find((agent) => agent.id === lead.agent)
                                ?.personalInfo?.firstName || "Onbekend"
                            ) : (
                              <Input
                                type="select"
                                value=""
                                onChange={(e) =>
                                  assignAgentToLead(lead.id, e.target.value)
                                }
                              >
                                <option value="">Selecteer een agent...</option>
                                {agents.map((agent) => (
                                  <option key={agent.id} value={agent.id}>
                                    {agent.personalInfo?.firstName ||
                                      "Onbekend"}
                                  </option>
                                ))}
                              </Input>
                            )}
                          </td>
                        )}
                        <td>
                          <Button
                            color="success"
                            size="sm"
                            onClick={() => openLeadInfo(lead)}
                          >
                            Verwerk
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={"Vorige"}
                nextLabel={"Volgende"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  filteredLeads.filter(
                    (lead) =>
                      !["lead", "new", "appointment"].includes(lead.status)
                  ).length / itemsPerPage
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickLeads}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal className="custom-modal" isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Nieuwe Lead</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Voornaam</Label>
              <Input
                type="text"
                value={newLead.customerDetails.firstName}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      firstName: e.target.value,
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Label>Achternaam</Label>
              <Input
                type="text"
                value={newLead.customerDetails.lastName}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      lastName: e.target.value,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Label className="mt-2">Email</Label>
          <Input
            type="email"
            value={newLead.customerDetails.email}
            onChange={(e) =>
              setNewLead({
                ...newLead,
                customerDetails: {
                  ...newLead.customerDetails,
                  email: e.target.value,
                },
              })
            }
          />
          <Label className="mt-2">Telefoon</Label>
          <Input
            type="text"
            value={newLead.customerDetails.phone}
            onChange={(e) =>
              setNewLead({
                ...newLead,
                customerDetails: {
                  ...newLead.customerDetails,
                  phone: e.target.value,
                },
              })
            }
          />
          <Row>
            <Col>
              <Label className="mt-2">Postcode</Label>
              <Input
                type="text"
                value={newLead.customerDetails.postcode}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      postcode: e.target.value,
                    },
                  })
                }
              />
              <Button
                color="info"
                size="sm"
                className="mt-2"
                onClick={handleAddressSearch}
              >
                Adres Ophalen
              </Button>
            </Col>
            <Col>
              <Label className="mt-2">Huisnummer</Label>
              <Input
                type="text"
                value={newLead.customerDetails.huisnummer}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      huisnummer: e.target.value,
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Toevoeging</Label>
              <Input
                type="text"
                value={newLead.customerDetails.toevoeging}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      toevoeging: e.target.value,
                    },
                  })
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="mt-2">Straat</Label>
              <Input
                type="text"
                value={newLead.customerDetails.straat}
                disabled
              />
            </Col>
            <Col>
              {" "}
              <Label className="mt-2">Plaats</Label>
              <Input
                type="text"
                value={newLead.customerDetails.plaats}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color="info" onClick={toggleNoteModal}>
                Notitie Toevoegen
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="notes">
                <h3>Notities</h3>
                <ul>
                  <Row>
                    {notes.map((a, i) => {
                      return (
                        <Col key={i} xs={6}>
                          <div
                            className="delete-button"
                            onClick={() => handleDeleteNote(i)}
                          >
                            X
                          </div>
                          <li>
                            <span>{a.title}</span>
                            {a.description}
                          </li>
                        </Col>
                      );
                    })}
                  </Row>
                </ul>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddLead}>
            Lead Toevoegen
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={offerModalOpen} toggle={toggleOfferModal}>
        <ModalHeader toggle={toggleOfferModal}>Lead informatie</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <h3>Persoonlijke gegevens</h3>
            </Col>
            <Col>
              <div className="customer-info">
                <div className="info-line">
                  <span>Naam:</span> {selectedLead?.customerDetails.firstName}{" "}
                  {selectedLead?.customerDetails.lastName}
                  <br />
                  <span>Telefoonnummer:</span>{" "}
                  {selectedLead?.customerDetails.phone}
                  <br />
                  <span>E-mailadres:</span>{" "}
                  {selectedLead?.customerDetails.email}
                  <br />
                </div>
              </div>
            </Col>
            <Col>
              <div className="customer-info">
                <div className="info-line">
                  <span>Adres:</span>
                  {selectedLead?.customerDetails.straat}{" "}
                  {selectedLead?.customerDetails.huisnummer}
                  <br />
                  {selectedLead?.customerDetails.postcode}{" "}
                  {selectedLead?.customerDetails.plaats}
                  <br />
                  {selectedLead?.customerDetails.provincie}
                  <br />
                </div>
              </div>
            </Col>
          </Row>
          <h5 className="mt-4">Bekijk locatie</h5>
          <a
            target="_blank"
            href={`https://maps.google.com/?q=${selectedLead?.customerDetails.straat}+${selectedLead?.customerDetails.huisnummer}+${selectedLead?.customerDetails.postcode}+${selectedLead?.customerDetails.plaats}`}
          >
            Google maps
          </a>

          {/* Dropdown for actions */}
          <h5 className="mt-4">Status wijzigen</h5>
          <Input
            type="select"
            onChange={(e) => updateLeadStatus(selectedLead?.id, e.target.value)}
            disabled={selectedLead?.status === "closed"}
          >
            <option value="">Selecteer een actie...</option>
            <option value="no_answer">Klant niet bereikbaar</option>
            <option value="wrong_number">Verkeerd nummer</option>
            <option value="not_interested">Niet meer geïnteresseerd</option>
            <option value="reclameren">Lead niet goed (reclameren)</option>
          </Input>
          {selectedLead?.notes.length > 0 && (
            <div className="notes-block-lead">
              <h5>Notities</h5>

              {selectedLead.notes.map((a, i) => {
                // console.log(a);
                return (
                  <div key={i}>
                    <div className="title">
                      {a.title} {a.content}
                    </div>
                    <div className="description">{a.description}</div>
                  </div>
                );
              })}
            </div>
          )}
          {!_.isEmpty(selectedLead?.appointment) && (
            <div className="notes-block-lead">
              <h5>Afspraken</h5>

              <div>
                <div className="title">{selectedLead?.appointmentType}</div>
                <div className="description">
                  {moment(selectedLead.appointment.toDate()).format("lll")}
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => setTaskModalOpen(true)}>
            Plan Afspraak
          </Button>
          <Button color="primary" onClick={handleCreateOffer}>
            Offerte Aanmaken
          </Button>
          <Button color="secondary" onClick={toggleOfferModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        className="custom-modal"
        isOpen={noteModalOpen}
        toggle={toggleNoteModal}
      >
        <ModalHeader toggle={toggleNoteModal}>Nieuwe Notitie</ModalHeader>
        <ModalBody>
          <Label>Titel</Label>
          <Input
            type="text"
            value={newNote.title}
            onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
          />
          <Label className="mt-2">Beschrijving</Label>
          <Input
            type="textarea"
            value={newNote.description}
            onChange={(e) =>
              setNewNote({ ...newNote, description: e.target.value })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddNote}>
            Notitie Toevoegen
          </Button>
          <Button color="secondary" onClick={toggleNoteModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <TaskModal
        isOpen={taskModalOpen}
        toggle={saveTask}
        onSave={handleScheduleAppointment}
        agents={agents}
        lead={selectedTaskLead}
        initialData={{
          title: "Afspraak plannen",
          type: "fysieke afspraak",
        }}
        refreshTasks={false}
        isLead={true}
      />
      <Modal
        isOpen={isReclaimNoteModalOpen}
        toggle={() => setReclaimNoteModalOpen(false)}
      >
        <ModalHeader toggle={() => setReclaimNoteModalOpen(false)}>
          Reden voor reclamatie
        </ModalHeader>
        <ModalBody>
          <Label>Beschrijf de reden voor reclameren:</Label>
          <Input
            type="textarea"
            value={reclaimReason}
            onChange={(e) => setReclaimReason(e.target.value)}
            placeholder="Voer de reden voor reclamatie in..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitReclaimNote}>
            Opslaan
          </Button>
          <Button
            color="secondary"
            onClick={() => setReclaimNoteModalOpen(false)}
          >
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default LeadsPage;
