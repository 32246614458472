import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db, fetchFinancingOptions } from "../firebaseConfig";
import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Offerte1 from "../templates/offertes/Offerte1";
import "./OfferteEdit.css";
import PdfGenerator from "../components/PdfGenerator";
import PdfViewerModal from "../components/PdfViewerModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useUser } from "../context/UserContext";
import { useDashboardData } from "../context/DataContext";
import Offerte2 from "../templates/offertes/Offerte2";

const EditAccordion = () => {
  const { data } = useDashboardData();

  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const [offerteData, setOfferteData] = useState({
    agent: null,
    createdAt: null,
    id: null,
    status: null,
    updatedAt: null,
    customerDetails: {},
    selectedProduct: null,
    selectedProducts: [],
    appliedDiscounts: [],
    selectedExtras: [],
    priceDetails: {
      basePrice: 0,
      VAT: 0,
      totalIncludingVAT: 0,
      discountedPrice: 0,
    },
    finalPrice: 0,
    inleiding: null,
  });

  const [activeStep, setActiveStep] = useState(1);
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState({
    postalCode: "",
    houseNumber: "",
    toevoeging: "",
  });
  const [addressDetails, setAddressDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]); // List of products available for selection
  const [packages, setPackages] = useState([]); // List of available packages
  const [selectedPackage, setSelectedPackage] = useState(null); // Selected package
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [financingOptions, setFinancingOption] = useState(null);

  const [isSolarModalOpen, setSolarModalOpen] = useState(false);
  const [solarPanelCount, setSolarPanelCount] = useState(1);

  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    const fetchFinance = async () => {
      const options = await fetchFinancingOptions();
      // console.log(options);
      setFinancingOption(options);
    };

    fetchFinance();
  }, []);

  useEffect(() => {
    const fetchOfferte = async () => {
      try {
        const docRef = doc(db, "offertes", id);
        const docSnap = await getDoc(docRef);
        // console.log(docSnap.data())
        if (docSnap.exists()) {
          const data = docSnap.data();
          setOfferteData({
            ...data,
            selectedProducts: data.selectedProducts || [],
            appliedDiscounts: data.appliedDiscounts || [],
          });
          setAddress({
            postalCode: docSnap.data().customerDetails?.postcode || "",
            houseNumber: docSnap.data().customerDetails?.huisnummer || "",
          });

          const packagesSnapshot = await getDocs(collection(db, "packages"));
          const fetchedPackages = [];
          packagesSnapshot.docs.filter((doc) => {
            let data = doc.data();

            if (user.groupId && user.userGroup?.percentage !== 100) {
              data.prijs = data.prijs * (user.userGroup.percentage / 100);
            }
            if (data.active) {
              fetchedPackages.push({
                id: doc.id,
                ...data,
              });
            }
          });
          setPackages(fetchedPackages);
          fetchProducts(docSnap.data().selectedProduct.id);
        } else {
          console.error("No such document!");
          navigate("/offertes");
        }
      } catch (error) {
        console.error("Error fetching offerte:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchOfferte();
    fetchProducts();
  }, [id, navigate]);

  // useEffect(() => {
  //   fetchProducts();
  // }, [offerteData.selectedProduct, offerteData.selectedExtras, activeStep]);

  useEffect(() => {
    if (offerteData.selectedProduct) {
      calculatePricesOld(offerteData);
      // console.log("changes")
    }
  }, [products, offerteData.appliedDiscounts]);

  const fetchProducts = () => {
    const productData = data?.products;
    if (productData.length >= 0) {
      try {
        let baseProducts = [];

        // if (offerteData.selectedProduct?.producten?.length > 0) {
        //   // Use producten from selectedProduct
        //   baseProducts =
        //     offerteData.selectedProduct.producten
        //       .map((a) => {
        //         const fullProduct = productData.find(
        //           (b) => b.id === a.productId
        //         );
        //         if (fullProduct) return { ...a, ...fullProduct };
        //         return null; // Exclude unmatched products
        //       })
        //       .filter(Boolean) || [];
        // } else {
        // Fallback: Fetch products associated with selectedProduct from data context
        const productIds = productData.map((p) => ({
          productId: p.id,
          isRequired: p.isRequired || false,
          inputType: p.inputType || "single", // Use default or fallback type
          ...p,
        }));
        baseProducts = productIds;
        // }
        // console.log(baseProducts);
        const selectedExtras = offerteData.selectedExtras || [];

        // Map base products from the selected package
        const mergedProducts = baseProducts.map((product) => {
          // Find a match in selectedExtras
          const matchingExtra = selectedExtras.find(
            (extra) => extra.productId === product.id
          );

          // Merge matchingExtra with product, or keep the original product
          return matchingExtra
            ? { ...product, ...matchingExtra } // Combine product and matchingExtra
            : { ...product, selected: false, quantity: 0 }; // Ensure all original products have default properties
        });

        // Add any custom extras directly from selectedExtras
        const customExtras = selectedExtras.filter((extra) => extra.isCustom);

        // Combine base products and custom extras
        const allProducts = [...mergedProducts, ...customExtras];
        // console.log(allProducts);
        // Update state
        setProducts(allProducts);
        setOfferteData((prev) => ({
          ...prev,
          selectedProducts: allProducts.filter((p) => p.selected),
        }));
      } catch (error) {
        console.error("Error fetching products from offerteData:", error);
      }
    }
  };

  const VAT_RATE = 0.21; // 21% VAT rate

  const handleProductSelection = (productId) => {
    setProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.id === productId) {
          return { ...product, selected: !product.selected };
        } else {
          return product;
        }
      });
    });

    setOfferteData((prev) => {
      // Find the product to toggle in the `products` state
      const product = products.find((p) => p.id === productId);

      if (!product) {
        console.error("Product not found");
        return prev; // Exit early if product is not found
      }

      // Check the current selection status
      const isSelected = prev.selectedExtras.some(
        (extra) => extra.id === productId
      );

      // Toggle selection: Add if not selected, remove if selected
      const updatedSelectedExtras = isSelected
        ? prev.selectedExtras.filter((extra) => extra.id !== productId) // Remove if already selected
        : [...prev.selectedExtras, { ...product, selected: true }]; // Add if not selected

      // Return updated offerteData
      const updatedData = {
        ...prev,
        selectedExtras: updatedSelectedExtras,
      };

      return {
        ...updatedData,
        ...calculatePrices(updatedData),
      };
    });
  };

  const handleQuantityChange = (productId, change) => {
    // Update the quantity in the products state
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId
          ? {
              ...product,
              quantity: Math.max((product.quantity || 0) + change, 0),
            }
          : product
      )
    );

    // Update the quantity in the selectedExtras of offerteData
    setOfferteData((prev) => {
      // Find the product in the selectedExtras
      const existingProductIndex = prev.selectedExtras.findIndex(
        (extra) => extra.id === productId
      );

      let updatedSelectedExtras = [...prev.selectedExtras];

      if (existingProductIndex > -1) {
        // Update the quantity for the existing product
        const updatedProduct = {
          ...updatedSelectedExtras[existingProductIndex],
          quantity: Math.max(
            (updatedSelectedExtras[existingProductIndex].quantity || 0) +
              change,
            0
          ),
        };

        // Remove the product if quantity becomes 0
        if (updatedProduct.quantity === 0) {
          updatedSelectedExtras.splice(existingProductIndex, 1);
        } else {
          updatedSelectedExtras[existingProductIndex] = updatedProduct;
        }
      } else if (change > 0) {
        // Add the product if it's not already in selectedExtras and change > 0
        const productToAdd = products.find((p) => p.id === productId);
        if (productToAdd) {
          updatedSelectedExtras.push({
            ...productToAdd,
            quantity: change,
          });
        }
      }

      // Recalculate prices
      const updatedData = {
        ...prev,
        selectedExtras: updatedSelectedExtras,
      };

      return {
        ...updatedData,
        ...calculatePrices(updatedData),
      };
    });
  };

  const handlePackageSelection = async (pkg) => {
    // console.log(pkg);
    setOfferteData((prev) => {
      const updatedData = {
        ...prev,
        selectedProduct: {
          ...pkg,
          solarPanelCount: pkg.solar ? 1 : 0, // Default to 1 panel if solar package
          solarPrice: pkg.solarPrice || 0, // Ensure solar price is stored
        }, // Update the selected package
        selectedProducts: [], // Reset selected products
      };

      // Base price (Package price)
      const basePrice = Number(pkg.prijs) || 0;

      // Calculate price of selected solar panels
      const solarPanelCost = pkg.solar ? (pkg.solarPrice || 0) * 1 : 0; // Default to 1 panel

      // Calculate extra products' price
      const extraProductsPrice = prev.selectedExtras.reduce(
        (total, product) => {
          if (product.type === "quantity") {
            // Multiply price by quantity for quantity-based products
            return (
              total + Number(product.price) * (Number(product.quantity) || 1)
            );
          } else if (product.type === "single") {
            // Add price directly for single-selection products
            return total + Number(product.price);
          }
          return total; // Fallback for undefined types
        },
        0
      );

      // Calculate price excluding VAT
      const priceExcludingVAT = basePrice + extraProductsPrice + solarPanelCost;

      // Apply discounts
      const appliedDiscounts = prev.appliedDiscounts || [];
      const totalDiscount = appliedDiscounts.reduce((total, discount) => {
        if (discount.type === "percentage") {
          // Apply percentage discount on the base price (before VAT)
          return total + (discount.value / 100) * priceExcludingVAT;
        } else if (discount.type === "fixed") {
          // Apply fixed discount
          return total + discount.value;
        }
        return total; // Fallback for undefined types
      }, 0);
      const discountedPrice = priceExcludingVAT - totalDiscount;
      // Ensure the discount does not exceed the total price
      // Calculate VAT
      const VAT = discountedPrice * VAT_RATE;

      // Total price including VAT
      const priceIncludingVAT = discountedPrice + VAT;

      // Update price details
      updatedData.priceDetails = {
        basePrice: priceExcludingVAT.toFixed(2),
        VAT: VAT.toFixed(2),
        totalIncludingVAT: priceIncludingVAT.toFixed(2),
        discountedPrice: discountedPrice.toFixed(2),
      };

      // Final price after discount
      updatedData.finalPrice = priceIncludingVAT.toFixed(2);

      return updatedData;
    });

    if (pkg.solar) {
      setSolarModalOpen(true);
    }
    // Fetch and update products for the selected package
    fetchProducts(pkg.id);
  };

  const updateSolarPanels = (newCount) => {
    setSolarPanelCount(newCount);
    setOfferteData((prev) => {
      const updatedProduct = {
        ...prev.selectedProduct,
        solarPanelCount: newCount, // Store inside selectedProduct
      };

      return {
        ...prev,
        selectedProduct: updatedProduct,
        ...calculatePrices({ ...prev, selectedProduct: updatedProduct }),
      };
    });
    setSolarModalOpen(false);
  };

  const handleDiscountChange = (index, key, value) => {
    setOfferteData((prev) => {
      const discounts = [...prev.appliedDiscounts];
      discounts[index] = { ...discounts[index], [key]: value };

      // Update discounts and recalculate prices
      const updatedData = {
        ...prev,
        appliedDiscounts: discounts,
      };

      // Recalculate prices with updated discounts
      return {
        ...updatedData,
        ...calculatePrices(updatedData),
      };
    });
  };

  const handleInleidingChange = (value) => {
    setOfferteData((prev) => {
      // Update discounts and recalculate prices
      const updatedData = {
        ...prev,
        inleiding: value,
      };

      // Recalculate prices with updated discounts
      return {
        ...updatedData,
      };
    });
  };

  const calculatePricesOld = (data) => {
    // console.log(data)
    const basePrice = parseFloat(data.selectedProduct?.prijs || 0);

    // Calculate extras price
    const extrasPrice = data.selectedExtras.reduce((total, extra) => {
      // console.log(extra);
      // console.log(extra.name, extra.inputType, extra.price);
      if (extra.inputType === "single" && extra.selected) {
        return total + parseFloat(extra.price || 0);
      }
      if (extra.inputType === "quantity" && extra.quantity > 0) {
        return (
          total + parseFloat(extra.price || 0) * parseFloat(extra.quantity || 0)
        );
      }
      return total;
    }, 0);
    // console.log(extrasPrice);
    const priceExcludingVAT = basePrice + extrasPrice;

    // Calculate discounts
    const totalDiscount = data.appliedDiscounts.reduce((total, discount) => {
      if (discount.type === "percentage") {
        return total + (discount.value / 100) * priceExcludingVAT;
      }
      return total + parseFloat(discount.value || 0);
    }, 0);
    const discountedPrice = priceExcludingVAT - totalDiscount;
    // Calculate VAT
    const VAT = discountedPrice * VAT_RATE;

    const totalIncludingVAT = discountedPrice + VAT;

    // Return    recalculated details
    const details = {
      basePrice: priceExcludingVAT.toFixed(2),
      VAT: VAT.toFixed(2),
      totalIncludingVAT: totalIncludingVAT.toFixed(2),
      discountedPrice: discountedPrice.toFixed(2),
    };

    return {
      priceDetails: details,
      finalPrice: totalIncludingVAT.toFixed(2),
    };
  };

  const handleUpdateOfferte = async () => {
    try {
      const updatedData = {
        ...offerteData,

        selectedProducts,
      };

      const docRef = doc(db, "offertes", id);
      await updateDoc(docRef, updatedData);
      alert("Offerte bijgewerkt!");
      navigate(`/offertes/${id}`);
    } catch (error) {
      console.error("Error updating offerte:", error);
      alert("Er is iets misgegaan bij het bijwerken van de offerte.");
    }
  };
  const handleNext = () => {
    setActiveStep((prev) => (prev < 6 ? prev + 1 : prev));
  };

  const handlePrevious = () => {
    setActiveStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleAddressValidation = async () => {
    try {
      const response = await axios.get(
        `https://api.pro6pp.nl/v1/autocomplete?auth_key=${process.env.REACT_APP_PRO6BB}&nl_sixpp=${address.postalCode}&streetnumber=${address.houseNumber}`
      );
      if (response.data && response.data.results.length > 0) {
        const result = response.data.results[0];
        setAddressDetails({
          straat: result.street,
          plaats: result.city,
          provincie: result.province,
        });
        setOfferteData({
          ...offerteData,
          customerDetails: {
            ...offerteData.customerDetails,
            huisnummer: address.houseNumber,
            toevoeging: address.addon,
            postcode: address.postalCode,
            straat: result.street,
            plaats: result.city,
            provincie: result.province,
          },
        });
      } else {
        alert("Invalid postal code or house number.");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      alert("Error validating address.");
    }
  };

  const generatePdf = async () => {
    // console.log("generating pdf");
    if (offerteData.selectedProduct.productPage) {
      const pdfBlob = await Offerte2(offerteData);
      setPdfBlob(pdfBlob);
    } else {
      const pdfBlob = await Offerte1(offerteData);
      setPdfBlob(pdfBlob);
    }

    // console.log(pdfBlob);
    // Open the modal
    toggleModal();
  };

  const processProducts = () => {
    const selectedProduct = offerteData.selectedProduct;
    const selectedExtras = offerteData.selectedExtras;
    // Predefined categories in lowercase
    const categories = {
      "hybride omvormers": [],
      energiebeheer: [], // Combine "Energiebeheer" and "EMS"
      meerwerk: [],
      overig: [], // Default category for unlisted products
    };

    // if (selectedProduct?.producten?.length > 0 && products.length > 0) {
    //   selectedProduct.producten.forEach((pkgProduct) => {
    //     const fullProduct = products.find(
    //       (product) => product.id === pkgProduct.productId
    //     );
    //     if (fullProduct) {
    //       const category = (fullProduct.category || "overig").toLowerCase();
    //       const normalizedCategory =
    //         category.includes("energiebeheer") || category.includes("ems")
    //           ? "energiebeheer"
    //           : categories[category]
    //           ? category
    //           : "overig";
    //       const isRequired = pkgProduct.type === "required";
    //       categories[normalizedCategory].push({
    //         ...fullProduct,
    //         inputStyle: pkgProduct.inputType || "single", // e.g., "single" or "quantity"
    //         selected:
    //           isRequired ||
    //           selectedExtras.some((extra) => extra.id === pkgProduct.productId),
    //         quantity:
    //           selectedExtras.find((extra) => extra.id === pkgProduct.productId)
    //             ?.quantity || 0,
    //         isRequired, // New field for required/optional
    //       });
    //     }
    //   });
    // } else {
    products.forEach((pkgProduct) => {
      const fullProduct = products.find(
        (product) => product.id === pkgProduct.productId
      );
      if (fullProduct) {
        const category = (fullProduct.category || "overig").toLowerCase();
        const normalizedCategory =
          category.includes("energiebeheer") || category.includes("ems")
            ? "energiebeheer"
            : categories[category]
            ? category
            : "overig";
        const isRequired = pkgProduct.type === "required";
        categories[normalizedCategory].push({
          ...fullProduct,
          inputStyle: pkgProduct.inputType || "single", // e.g., "single" or "quantity"
          selected:
            isRequired ||
            selectedExtras.some((extra) => extra.id === pkgProduct.productId),
          quantity:
            selectedExtras.find((extra) => extra.id === pkgProduct.productId)
              ?.quantity || 0,
          isRequired, // New field for required/optional
        });
      }
    });
    // }

    const orderedCategories = Object.keys(categories)
      .filter((category) => categories[category].length > 0)
      .reduce((ordered, category) => {
        ordered[category] = categories[category];
        return ordered;
      }, {});

    // console.log(orderedCategories);
    setGroupedProducts(orderedCategories); // Update grouped products for the UI
    setLoading(false);
  };

  useEffect(() => {
    processProducts(); // Ensure grouped products are processed when dependencies change
  }, [products]);

  const calculatePrices = (data) => {
    // 1. Determine if the package is solar and calculate package cost.
    const isSolar = !!data.selectedProduct?.solar;
    const productPrice = parseFloat(data.selectedProduct?.prijs || 0);

    // For solar packages, package cost = price per panel * solarPanelCount.
    // For non-solar packages, it's simply the product's price.
    const packageCost = isSolar
      ? productPrice * Number(data.selectedProduct.solarPanelCount || 1)
      : productPrice;
    // 2. Separate extra products into taxable and non-taxable (meerwerk) extras.
    let taxableExtras = 0;
    let nonTaxableExtras = 0;

    data.selectedExtras.forEach((extra) => {
      let extraCost = 0;
      if (!extra.price) extra.price = "0,00";
      extra.price = extra.price.replace(/,/g, ".");
      if (extra.inputType === "quantity") {
        console.log(extra);
        extraCost = Number(extra.price) * (Number(extra.quantity) || 0);
      } else {
        extraCost = Number(extra.price);
      }

      // If extra.category equals "meerwerk" (case-insensitive), exclude it from VAT.
      if (
        isSolar &&
        extra.category &&
        extra.category.toLowerCase() === "meerwerk"
      ) {
        nonTaxableExtras += extraCost;
      } else {
        taxableExtras += extraCost;
      }
    });

    // 3. Total before discount = package cost + all extras.
    const totalBeforeDiscount = packageCost + taxableExtras + nonTaxableExtras;

    // 4. Apply discounts over the entire total.
    //    (Assuming data.appliedDiscounts is an array of discount objects.)
    let discountedTotal = totalBeforeDiscount;
    (data.appliedDiscounts || []).forEach((discount) => {
      if (discount.type === "percentage") {
        discountedTotal -= (discount.value / 100) * discountedTotal;
      } else {
        discountedTotal -= parseFloat(discount.value || 0);
      }
    });
    discountedTotal = Math.max(discountedTotal, 0);

    // The total discount applied:
    const discountAmount = totalBeforeDiscount - discountedTotal;

    // 5. Determine the taxable base.
    //    For non-solar packages, the taxable base includes packageCost and taxable extras.
    //    For solar packages, the packageCost is tax‑free so only taxableExtras are subject to VAT.
    const taxableBase = (isSolar ? 0 : packageCost) + taxableExtras;

    // Allocate the discount proportionally to the taxable base.
    const allocatedTaxableDiscount =
      totalBeforeDiscount > 0
        ? (taxableBase / totalBeforeDiscount) * discountAmount
        : 0;

    const discountedTaxable = taxableBase - allocatedTaxableDiscount;

    // 6. Calculate VAT on the discounted taxable portion.
    const VAT = discountedTaxable * VAT_RATE;

    // 7. Final total including VAT.
    const totalIncludingVAT = discountedTotal + VAT;

    return {
      priceDetails: {
        basePrice: totalBeforeDiscount.toFixed(2), // Total before discounts.
        discountedPrice: discountedTotal.toFixed(2), // Total after discounts.
        VAT: VAT.toFixed(2), // VAT on taxable items.
        totalIncludingVAT: totalIncludingVAT.toFixed(2), // Final total price.
      },
      finalPrice: totalIncludingVAT.toFixed(2),
    };
  };

  if (loading) {
    return <div>Loading offerte...</div>;
  }

  // console.log(offerteData)
  return (
    <Container>
      <h2>Edit Offerte</h2>
      <Row>
        <Col md={8}>
          <div className={`accordion-step ${activeStep === 1 ? "active" : ""}`}>
            <h3>1. Klantgegevens</h3>
            {activeStep === 1 && (
              <Card className="p-4 shadow-sm">
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Voornaam</Label>
                      <Input
                        type="text"
                        value={offerteData.customerDetails?.firstName || ""}
                        onChange={(e) =>
                          setOfferteData({
                            ...offerteData,
                            customerDetails: {
                              ...offerteData.customerDetails,
                              firstName: e.target.value,
                            },
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Achternaam</Label>
                      <Input
                        type="text"
                        value={offerteData.customerDetails?.lastName || ""}
                        onChange={(e) =>
                          setOfferteData({
                            ...offerteData,
                            customerDetails: {
                              ...offerteData.customerDetails,
                              lastName: e.target.value,
                            },
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        value={offerteData.customerDetails?.email || ""}
                        onChange={(e) =>
                          setOfferteData({
                            ...offerteData,
                            customerDetails: {
                              ...offerteData.customerDetails,
                              email: e.target.value,
                            },
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Telefoonnummer</Label>
                      <Input
                        type="text"
                        value={offerteData.customerDetails?.phone || ""}
                        onChange={(e) =>
                          setOfferteData({
                            ...offerteData,
                            customerDetails: {
                              ...offerteData.customerDetails,
                              phone: e.target.value,
                            },
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Postcode</Label>
                      <Input
                        type="text"
                        value={address.postalCode}
                        onChange={(e) =>
                          setAddress({ ...address, postalCode: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Huisnummer</Label>
                      <Input
                        type="text"
                        value={address.houseNumber}
                        onChange={(e) =>
                          setAddress({
                            ...address,
                            houseNumber: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Toevoeging</Label>
                      <Input
                        type="text"
                        value={address.toevoeging}
                        onChange={(e) =>
                          setAddress({ ...address, toevoeging: e.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      marginTop: "30px",
                    }}
                  >
                    <Button color="warning" onClick={handleAddressValidation}>
                      Haal adres op
                    </Button>
                  </Col>
                </Row>
                {offerteData.customerDetails.straat && (
                  <Row>
                    <Col md={12}>
                      <p>
                        <strong>Straat:</strong>{" "}
                        {offerteData.customerDetails.straat}
                        <br />
                        <strong>Plaats:</strong>{" "}
                        {offerteData.customerDetails.plaats}
                        <br />
                        <strong>Provincie:</strong>{" "}
                        {offerteData.customerDetails.provincie}
                      </p>
                    </Col>
                  </Row>
                )}
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    color="secondary"
                    onClick={handlePrevious}
                    disabled={activeStep === 1}
                  >
                    Terug
                  </Button>
                  <Button color="primary" onClick={handleNext}>
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>
          {/* Step 2: Product Selection */}
          <div className={`accordion-step ${activeStep === 2 ? "active" : ""}`}>
            <h3>2. Pakketselectie</h3>
            {activeStep === 2 && (
              <Card className="p-4 shadow-sm">
                <h5>Selecteer een pakket</h5>
                <div className="package-list">
                  {packages.map((pkg) => {
                    // console.log(pkg)
                    // console.log(selectedPackage)
                    return (
                      <div
                        key={pkg.id}
                        className={`package-item ${
                          offerteData.selectedProduct.id === pkg.id
                            ? "active"
                            : ""
                        }`}
                      >
                        <Label check>
                          <Input
                            type="radio"
                            name="package"
                            checked={offerteData.selectedProduct.id === pkg.id}
                            onChange={() => handlePackageSelection(pkg)}
                          />
                          {pkg.naam} - €{pkg.prijs.toLocaleString()}
                        </Label>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <Button
                    color="secondary"
                    onClick={handlePrevious}
                    disabled={activeStep === 1}
                  >
                    Terug
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleNext}
                    disabled={!offerteData.selectedProduct}
                  >
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>
          {/* Step 3: Product Selection */}
          <div className={`accordion-step ${activeStep === 3 ? "active" : ""}`}>
            <h3>3. Productselectie</h3>
            {activeStep === 3 && (
              <Card className="p-4 shadow-sm">
                <h5>Selecteer producten</h5>
                <div className="product-groups">
                  {Object.entries(groupedProducts).map(
                    ([category, products]) => (
                      <div key={category} className="product-category">
                        <h5 className="category-title">
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </h5>
                        <div className="category-products">
                          {products.map((product) => (
                            <div key={product.id} className="product-item">
                              {product.inputType === "quantity" ? (
                                <div className="quantity-product">
                                  <Label>
                                    <strong>{product.name}</strong> - €
                                    {console.log(product.name, product.price)}
                                    {parseFloat(product.price).toLocaleString()}
                                  </Label>
                                  <div className="quantity-controls">
                                    <Button
                                      size="sm"
                                      color="secondary"
                                      onClick={() =>
                                        handleQuantityChange(product.id, -1)
                                      }
                                      disabled={
                                        product.isRequired ||
                                        product.quantity <= 0
                                      }
                                    >
                                      -
                                    </Button>
                                    <Input
                                      type="number"
                                      value={product.quantity || 0}
                                      readOnly
                                    />
                                    <Button
                                      size="sm"
                                      color="primary"
                                      onClick={() =>
                                        handleQuantityChange(product.id, 1)
                                      }
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={product.selected}
                                    disabled={product.isRequired}
                                    onChange={() =>
                                      handleProductSelection(product.id)
                                    }
                                  />
                                  {product.name} - €
                                  {parseFloat(product.price).toLocaleString()}
                                </Label>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <Button color="secondary" onClick={handlePrevious}>
                    Terug
                  </Button>
                  <Button color="primary" onClick={handleNext}>
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>

          {/* Add additional steps */}
          <div className={`accordion-step ${activeStep === 4 ? "active" : ""}`}>
            <h3>4. Korting</h3>
            {/* Step 4: Discount */}
            {activeStep === 4 && (
              <Card className="p-4 shadow-sm">
                <div className="discount-list">
                  {offerteData.appliedDiscounts.map((discount, index) => (
                    <Row key={index} className="mb-3 align-items-center">
                      <Col md={4}>
                        <Input
                          type="text"
                          value={discount.name || ""}
                          placeholder="Naam van korting"
                          onChange={(e) =>
                            handleDiscountChange(index, "name", e.target.value)
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          type="number"
                          value={discount.value || ""}
                          placeholder="Waarde"
                          onChange={(e) =>
                            handleDiscountChange(
                              index,
                              "value",
                              parseFloat(e.target.value) || 0
                            )
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <Input
                          type="select"
                          value={discount.type || "fixed"}
                          onChange={(e) =>
                            handleDiscountChange(index, "type", e.target.value)
                          }
                        >
                          <option value="fixed">Vast bedrag (€)</option>
                          <option value="percentage">Percentage (%)</option>
                        </Input>
                      </Col>
                      <Col md={2}>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() =>
                            setOfferteData((prev) => {
                              const discounts = prev.appliedDiscounts.filter(
                                (_, i) => i !== index
                              );
                              const updatedData = {
                                ...prev,
                                appliedDiscounts: discounts,
                              };
                              return {
                                ...updatedData,
                                ...calculatePricesOld(updatedData),
                              };
                            })
                          }
                        >
                          Verwijderen
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
                <Button
                  color="secondary"
                  onClick={() =>
                    setOfferteData((prev) => {
                      const discounts = [
                        ...prev.appliedDiscounts,
                        { name: "", value: 0, type: "fixed" },
                      ];
                      const updatedData = {
                        ...prev,
                        appliedDiscounts: discounts,
                      };
                      return {
                        ...updatedData,
                        ...calculatePricesOld(updatedData),
                      };
                    })
                  }
                >
                  Voeg Korting Toe
                </Button>

                <div className="d-flex justify-content-between mt-4">
                  <Button color="secondary" onClick={handlePrevious}>
                    Terug
                  </Button>
                  <Button color="primary" onClick={handleNext}>
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>

          {/* Step 5: Payment Options */}
          <div className={`accordion-step ${activeStep === 5 ? "active" : ""}`}>
            <h3>5. Betalingscondities</h3>
            {activeStep === 5 && (
              <Card className="p-4 shadow-sm">
                <h5>Selecteer betalingscondities</h5>
                <div className="payment-options">
                  <ul className="financing-options">
                    {/* Ensure "100% eigen middelen" is always listed first */}
                    {financingOptions
                      ?.sort((a, b) =>
                        a.name === "100% uit eigen middelen" ? -1 : 1
                      )
                      .map((option) => (
                        <li key={option.id} className="mb-3">
                          <Input
                            type="radio"
                            name="financingOption"
                            id={option.id}
                            checked={
                              offerteData.selectedFinancingOption?.id ===
                              option.id
                            }
                            onChange={() =>
                              setOfferteData((prev) => ({
                                ...prev,
                                selectedFinancingOption: option,
                              }))
                            }
                          />
                          <Label for={option.id}>
                            {option.name} <span>{option.paymentCondition}</span>
                          </Label>
                        </li>
                      ))}
                  </ul>
                  {/* Ensure payment options are fetched and sorted */}
                </div>

                {/* Show financing partners dropdown if applicable */}
                {offerteData.selectedFinancingOption?.financingParties?.length >
                  0 && (
                  <FormGroup>
                    <Label for="financingPartner">
                      Kies een financieringspartner
                    </Label>
                    <Input
                      type="select"
                      id="financingPartner"
                      value={
                        offerteData.selectedFinancingOption.selectedPartner ||
                        ""
                      }
                      onChange={(e) =>
                        setOfferteData((prev) => ({
                          ...prev,
                          selectedFinancingOption: {
                            ...prev.selectedFinancingOption,
                            selectedPartner: e.target.value,
                          },
                        }))
                      }
                    >
                      <option value="">Selecteer een partner</option>
                      {offerteData.selectedFinancingOption.financingParties.map(
                        (party, index) => (
                          <option key={index} value={party}>
                            {party}
                          </option>
                        )
                      )}
                    </Input>
                  </FormGroup>
                )}

                <div className="d-flex justify-content-between mt-4">
                  <Button color="secondary" onClick={handlePrevious}>
                    Terug
                  </Button>
                  <Button color="primary" onClick={handleNext}>
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>

          {/* Step 6: Inleiding */}
          <div className={`accordion-step ${activeStep === 6 ? "active" : ""}`}>
            <h3>
              6. Offerte inleiding{" "}
              <small>(Let op! Dit word realtime aangepast.)</small>
            </h3>
            {activeStep === 6 && (
              <Card className="p-4 shadow-sm">
                <Input
                  type="textarea"
                  value={offerteData.inleiding}
                  onChange={(e) =>
                    setOfferteData((prev) => ({
                      ...prev,
                      inleiding: e.target.value,
                    }))
                  }
                  placeholder="Schrijf hier een inleiding voor de offerte... Laat leeg voor het standaard bericht"
                  rows="10"
                />

                {/* <ReactQuill
                  value={offerteData.inleiding || ""} // Use the inleiding from the offerteData state
                  onChange={(content) =>
                    setOfferteData((prev) => ({ ...prev, inleiding: content }))
                  }
                  placeholder="Schrijf een inleiding voor de offerte. Laat leeg voor het standaard bericht"
                  theme="snow" // Use 'bubble' for a different style
                /> */}
                <div className="d-flex justify-content-between mt-4">
                  <Button color="secondary" onClick={handlePrevious}>
                    Terug
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleNext();
                      // Save updated payment condition details
                      handleUpdateOfferte();
                    }}
                  >
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>
        </Col>
        {/* Sidebar */}
        <Col md={4}>
          <div className="sticky-div">
            <div className="offerte-watch mb-3">
              <Button
                color="success"
                block={true}
                onClick={handleUpdateOfferte}
              >
                Bijwerken
              </Button>

              <Button color={"primary"} block={true} onClick={generatePdf}>
                Offerte Bekijken
              </Button>
            </div>

            <Card className="p-4 shadow-sm">
              <h5>Overzicht</h5>
              <div>
                <Row style={{ marginBottom: 20, marginTop: 20 }}>
                  <Col>
                    {/* {console.log(offerteData)} */}
                    <img
                      src={offerteData?.selectedProduct?.images[0]}
                      alt={offerteData?.selectedProduct?.naam}
                      style={{ width: "70%", borderRadius: "4px" }}
                    />
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ margin: "20px 0" }}>
                      {offerteData?.selectedProduct?.naam}
                    </h5>
                  </Col>
                </Row>
                <p
                  dangerouslySetInnerHTML={{
                    __html: offerteData?.selectedProduct?.beschrijving,
                  }}
                ></p>
                <div className="separator price"></div>
                <h5>Meerwerk</h5>
                {offerteData?.selectedExtras?.length > 0 && (
                  <ul>
                    {offerteData?.selectedExtras.map((a, i) => {
                      // console.log(a)
                      return (
                        <li key={i}>
                          {a.name}{" "}
                          {a.inputType === "quantity" && <>X {a.quantity}</>}
                        </li>
                      );
                    })}
                  </ul>
                )}

                <div className="separator divider price"></div>
                <p className="pricing">
                  Prijs (excl. BTW):
                  <span>€{offerteData.priceDetails.basePrice}</span>
                </p>

                {Number(offerteData.priceDetails.basePrice) -
                  Number(offerteData.priceDetails.discountedPrice) >
                  0 && (
                  <>
                    <p className="pricing discount">
                      - Korting:{" "}
                      <span>
                        - €
                        {(
                          offerteData.priceDetails.basePrice -
                          offerteData.priceDetails.discountedPrice
                        ).toLocaleString()}
                      </span>
                    </p>
                    <p className="pricing">
                      Totaal na korting :{" "}
                      <span>
                        €
                        {offerteData.priceDetails.discountedPrice.toLocaleString()}
                      </span>
                    </p>
                  </>
                )}
                <div className="separator price"></div>

                <p className="pricing">
                  BTW (21%):
                  <span>€{offerteData.priceDetails.VAT}</span>
                </p>
                <div className="separator price"></div>
                <p className="pricing total-price">
                  Totaal (incl. BTW):
                  <span>€{offerteData.priceDetails.totalIncludingVAT}</span>
                </p>
                <div className="separator price"></div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      {/* {activeStep === 4 && (
                <Button color="success" onClick={handleUpdateOfferte}>
                    Bijwerken
                </Button>
            )} */}
      <PdfViewerModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        pdfBlob={pdfBlob}
      />
      {isSolarModalOpen && (
        <Modal
          isOpen={isSolarModalOpen}
          toggle={() => setSolarModalOpen(false)}
        >
          <ModalHeader>Selecteer het aantal zonnepanelen</ModalHeader>
          <ModalBody>
            <Label>Aantal zonnepanelen</Label>
            <Input
              type="number"
              min="1"
              value={solarPanelCount}
              onChange={(e) => setSolarPanelCount(Number(e.target.value))}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setSolarModalOpen(false)}>
              Annuleren
            </Button>
            <Button
              color="primary"
              onClick={() => updateSolarPanels(solarPanelCount)}
            >
              Bevestigen
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};

export default EditAccordion;
