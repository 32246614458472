import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  ThumbnailView,
  Print,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { registerLicense } from "@syncfusion/ej2-base";
import SchouwReport from "../../templates/schouw/SchouwReportBatterij";
import _ from "lodash";
import Offerte1 from "../../templates/offertes/Offerte1";

registerLicense(
  "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9RdEZiXH9ccnZRQ2BU"
);

const SchouwCheckModal = ({ isOpen, onClose, lead, onApprove, onReject }) => {
  const [newSchouwDate, setNewSchouwDate] = useState("");
  const [newSchouwTime, setNewSchouwTime] = useState("");
  const [isRejecting, setIsRejecting] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);

  useEffect(() => {
    console.log("shouw check modal");
    if (!_.isEmpty(lead)) generatePdf(lead);
  }, [lead]);

  const generatePdf = async (lead) => {
    if (lead) {
      const pdfBlob = await SchouwReport(lead);
      console.log(pdfBlob);
      if (pdfBlob) {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
      }
    }

    // Open the modal
  };

  const downloadReport = async () => {
    try {
      // Generate PDF blob
      const pdfBlob = await SchouwReport(lead);

      // Create a temporary URL for the blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `Offerte-${
        lead.customerDetails?.firstName || "unknown"
      }.pdf`;
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error generating or downloading PDF:", error);
    }
  };
  const handleReject = () => {
    onReject(lead, { date: newSchouwDate, time: newSchouwTime });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>Schouwrapport Controle</ModalHeader>
      <ModalBody className="pdf">
        {isRejecting && (
          <div>
            <FormGroup>
              <Label for="newSchouwDate">Nieuwe Schouwdatum</Label>
              <Input
                type="date"
                id="newSchouwDate"
                value={newSchouwDate}
                onChange={(e) => setNewSchouwDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="newSchouwTime">Nieuwe Schouwtijd</Label>
              <Input
                type="time"
                id="newSchouwTime"
                value={newSchouwTime}
                onChange={(e) => setNewSchouwTime(e.target.value)}
              />
            </FormGroup>
          </div>
        )}
        {!isRejecting && (
          <>
            {pdfUrl && (
              <PdfViewerComponent
                id="container"
                className="pdf-iframe"
                documentPath={pdfUrl}
                resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
                style={{ height: "calc(100vh - 150px)" }}
                enableToolbar={false}
                zoomMode="FitToPage"
                height="100%"
              >
                <Inject
                  services={[
                    Toolbar,
                    Magnification,
                    Navigation,
                    ThumbnailView,
                    Print,
                  ]}
                />
              </PdfViewerComponent>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {!isRejecting ? (
          <>
            <Button color="success" onClick={() => onApprove(lead)}>
              Schouw Goedkeuren
            </Button>
            <Button color="danger" onClick={() => setIsRejecting(true)}>
              Schouw Afkeuren
            </Button>
          </>
        ) : (
          <Button color="primary" onClick={handleReject}>
            Bevestig Nieuwe Schouw
          </Button>
        )}
        <Button color="warning" onClick={downloadReport}>
          download
        </Button>
        <Button color="secondary" onClick={onClose}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SchouwCheckModal;
